import React from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { ContentOne } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import { VideoPlayer } from 'components/VideoPlayer'
import hudl_data from 'assets/images/features/hudl_data.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'
import { ContentTwo } from '../../components/DetailDescription'

const FeatureTwo = (props) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
            <div className={classes.videoCover}>
              <img className={classes.logoImg} src={hudl_data} alt='' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <ContentTwo index={1} />
          </Grid>
        </Mobile>

        <Default>
          <div className="center-between">
            <Grid item xs={6} className="center-center">
              <img className={classes.logoImg} src={hudl_data} alt='' />
            </Grid>
            <Grid item xs={6}>
              <ContentTwo index={1} />
            </Grid>
          </div>
        </Default>
      </Grid>

    </Container>
  )
}

export default FeatureTwo
