import { takeEvery, takeLatest } from 'redux-saga/effects'
import * as CONSTANTS from 'redux/modules/sheet/constants'
import apiCall from '../api/apiCall'
import { call, put } from 'redux-saga/effects'
import {
  clearLiveData,
  updateLocalLiveData,
  updateSheetName
} from '../modules/sheet/actions'

const doCreateLiveData = apiCall({
  type: CONSTANTS.CREATE_LIVEDATA,
  method: 'post',
  path: ({ payload }) => `/livedata/`
})

const doUpdateLiveData = apiCall({
  type: CONSTANTS.UPDATE_LIVEDATA,
  method: 'put',
  path: ({ payload }) => `/livedata/${payload.id}`
})

const doDeleteLiveData = apiCall({
  type: CONSTANTS.UPDATE_LIVEDATA,
  method: 'delete',
  path: ({ payload }) => `/livedata/${payload.id}`
})

const doGetLiveDataByUser = apiCall({
  type: CONSTANTS.GET_LIVEDATA_BYUSER,
  method: 'get',
  path: ({ payload }) => `/livedata/`
})


const doGetLiveData = apiCall({
  type: CONSTANTS.GET_LIVEDATA,
  method: 'get',
  path: ({ payload }) => `/livedata/${payload.id}`
})

const doGetDropdownInput = apiCall({
  type: CONSTANTS.GET_DROPDOWNINPUT,
  method: 'post',
  path: '/dropdowninput/getDropdownByUserId/',
})

const doUpdateDropdownInput = apiCall({
  type: CONSTANTS.UPDATE_DROPDOWNINPUT,
  method: 'post',
  path: '/dropdowninput/updateDropdown/'
})

const doDeleteDropdownInput = apiCall({
  type: CONSTANTS.DELETE_DROPDOWNINPUT,
  method: 'post',
  path: '/dropdowninput/deleteDropdownByUserId/'
})

export default function* rootSaga() {
  yield takeLatest(CONSTANTS.CREATE_LIVEDATA, doCreateLiveData)
  yield takeLatest(CONSTANTS.GET_LIVEDATA_BYUSER, doGetLiveDataByUser)
  yield takeLatest(CONSTANTS.GET_LIVEDATA, doGetLiveData)
  // yield takeLatest(CONSTANTS.CLEAR_LIVEDATA, ()=>{
  //   console.log("run clear function")
  //   put({
  //     type: `${CONSTANTS.CLEAR_LIVEDATA}/success`,
  //     payload: null
  //   })
  // })
  yield put(clearLiveData())
  yield put(updateSheetName())
  yield put(updateLocalLiveData())
  yield takeLatest(CONSTANTS.UPDATE_LIVEDATA, doUpdateLiveData)
  yield takeLatest(CONSTANTS.DELETE_LIVEDATA, doDeleteLiveData)
  yield takeLatest(CONSTANTS.GET_DROPDOWNINPUT, doGetDropdownInput)
  yield takeLatest(CONSTANTS.UPDATE_DROPDOWNINPUT, doUpdateDropdownInput)
  yield takeLatest(CONSTANTS.DELETE_DROPDOWNINPUT, doDeleteDropdownInput)
}
