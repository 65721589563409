import { createAction } from 'redux-actions'
import * as CONSTANTS from './constants'

export const createLiveData = createAction(CONSTANTS.CREATE_LIVEDATA)
export const updateLiveData = createAction(CONSTANTS.UPDATE_LIVEDATA)
export const updateLocalLiveData = createAction(CONSTANTS.UPDATE_LOCAL_LIVEDATA)
export const updateSheetName = createAction(CONSTANTS.UPDATE_SHEET_NAME)
export const getLiveData = createAction(CONSTANTS.GET_LIVEDATA)
export const deleteLiveData = createAction(CONSTANTS.DELETE_LIVEDATA)
export const clearLiveData = createAction(CONSTANTS.CLEAR_LIVEDATA)
export const getLiveDataByUser = createAction(CONSTANTS.GET_LIVEDATA_BYUSER)

export const getDropdownInput = createAction(CONSTANTS.GET_DROPDOWNINPUT)
export const updateDropdownInput = createAction(CONSTANTS.UPDATE_DROPDOWNINPUT)