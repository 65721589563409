import { handleActions } from 'redux-actions'
import { requestSuccess, requestFail } from 'redux/api/request'
import * as CONSTANTS from './constants'

const getInitialState = () => {
  return {
    status: 'INIT',
    error: null,
    subscriptionInfo: {},
    subscriptionPayPalInfo: {},
    subscribeDetail: {},
    invoices:[],
    paypalInvoices:[],
    adminInvoices: []
  }
}

export default handleActions({
  [CONSTANTS.CREATE_STRIPE_CUSTOMER]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.CREATE_STRIPE_CUSTOMER)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
  }),
  [requestFail(CONSTANTS.CREATE_STRIPE_CUSTOMER)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
  }),
  
  [requestSuccess(CONSTANTS.GET_SUBSCRIPTION_INFO)]: (state, { payload }) => ({
    ...state,
    subscriptionInfo: payload.stripeInfo,
    last4:payload.last4
  }),
  [requestFail(CONSTANTS.GET_SUBSCRIPTION_INFO)]: (state, { payload }) => ({
    ...state,
    subscriptionInfo: payload.stripeInfo,
    last4:payload.last4
  }),

  [CONSTANTS.GET_SUBSCRIBE_DETAIL]: (state, { payload }) => ({
    ...state,
    subscribeDetail: {},
  }),
  [requestSuccess(CONSTANTS.GET_SUBSCRIBE_DETAIL)]: (state, { payload }) => ({
    ...state,
    subscribeDetail: payload,
  }),
  [requestFail(CONSTANTS.GET_SUBSCRIBE_DETAIL)]: (state, { payload }) => ({
    ...state,
    subscribeDetail: payload,
  }),

  [CONSTANTS.GET_PAYPAL_SUBSCRIPTION_DETAIL]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.GET_PAYPAL_SUBSCRIPTION_DETAIL)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    subscriptionPayPalInfo: payload,
  }),
  [requestFail(CONSTANTS.GET_PAYPAL_SUBSCRIPTION_DETAIL)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    subscriptionPayPalInfo: payload,
  }),

  [requestSuccess(CONSTANTS.GET_INVOICES)]: (state, { payload }) => ({
    ...state,
    invoices: payload,
  }),
  [requestFail(CONSTANTS.GET_INVOICES)]: (state, { payload }) => ({
    ...state,
    invoices: [],
  }),

  [requestSuccess(CONSTANTS.GET_PAYPAL_INVOICES)]: (state, { payload }) => ({
    ...state,
    paypalInvoices: payload,
  }),
  [requestFail(CONSTANTS.GET_PAYPAL_INVOICES)]: (state, { payload }) => ({
    ...state,
    paypalInvoices: [],
  }),

  [requestSuccess(CONSTANTS.GET_ADMIN_INVOICES)]: (state, { payload }) => ({
    ...state,
    adminInvoices: payload,
  }),
  [requestFail(CONSTANTS.GET_ADMIN_INVOICES)]: (state, { payload }) => ({
    ...state,
    adminInvoices: payload,
  }),
  
}, getInitialState())
