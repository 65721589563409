import { get } from 'lodash'

export const liveDataSelector = (state) =>
  get(state, 'sheet.liveData', {})

export const dropdownInputSelector = (state) =>
  get(state, 'sheet.dropdownInput', {})

export const sheetStatusSelector = (state) =>
  get(state, 'sheet.status', 'SUCCESS')

export const sheetNameSelector = (state) =>
  get(state, 'sheet.sheetName', 'SUCCESS')