import React from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { DetailGameSheet } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import logo_text from 'assets/images/logo/logo_text.png'
import * as cx from 'classnames'

const HomeTraxDescription = (props) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
            <div className={classes.videoCover}>
              <img className={classes.logoImg} src={logo_text} alt='' />
            </div>
          </Grid>
          <Grid item xs={12}>
            <DetailGameSheet />
          </Grid>
        </Mobile>

        <Default className={cx("center-between")}>
          <Grid item xs={6} className="center-center">
            <img className={classes.logoImg} src={logo_text} alt='' />
          </Grid>
          <Grid item xs={6}>
            <DetailGameSheet />
          </Grid>
        </Default>
      </Grid>

    </Container>
  )
}

export default HomeTraxDescription
