import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import App from './containers/AppContainer'
import * as serviceWorker from './serviceWorker'
import Themes from './themes'
import { STRIPE_PUBLIC_KEY } from 'helpers/utils'
import { loadStripe } from "@stripe/stripe-js"
import {
  Elements,
} from "@stripe/react-stripe-js"
import './index.scss'
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
      <CssBaseline />
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
  </ThemeProvider>,
  document.getElementById('root'))

serviceWorker.unregister()
