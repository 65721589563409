import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import useStyles from './MobileHeader.styles.js'
import mobile_logo from 'assets/images/logo/mobile_logo.svg'
import white_sheet_logo from 'assets/images/logo/logo_notext.png'
import menuIcon from 'assets/images/Icon/menu.svg'
import { NavMenu } from 'containers/NavMenu'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { VerifyEmailAlert } from 'components/CustomAlert'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isClosedVerificationSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'

const MobileHeader = ({
  isLandingPage,
  isMovable,
  currentUser,
  isClosedVerification,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const mobile_menu_img = isLandingPage ? menuIcon : menuIcon
  const [open, setOpen] = useState(false)
  const [classType, setClassType] = useState(classes.landingRoot)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')

  useEffect(() => {
    const setClassByType = () => {
      if (isLandingPage) {
        setClassType(classes.landingRoot)
      } else {
        setClassType(classes.normalRoot)
      }
    }
    setClassByType()
  }, [isLandingPage, classes])

  const handleLogo = () => {
    history.push('/')
  }

  return (
    <>
      <AppBar className={classType} position={isMovable ? 'absolute' : 'fixed'}>
        <Toolbar className="center-between">
          <img className={classes.logo_image} src={white_sheet_logo} onClick={handleLogo} alt='logo' />
          <img className={classes.image} src={mobile_menu_img} alt='menu' onClick={() => setOpen(true)} />
          {/* {
            authInfo().tokens ?
              <img className={classes.image} src={user_mobile_img} alt='user' onClick={handleClickUser} />
              :
              <Button
                className={classes.headerButton}
                onClick={handleSignup}
              >
                Start Free Now
            </Button>
          } */}
        </Toolbar>
      </AppBar>

      <Drawer
        anchor='right'
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.menu}>
          <NavMenu onClose={() => {
            setOpen(false)}} />
        </div>
      </Drawer>
    </>
  )
}

MobileHeader.propTypes = {
  isLandingPage: PropTypes.bool,
  isClosedVerification: PropTypes.bool,
  currentUser: PropTypes.any,
}

const actions = {
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  isClosedVerification: isClosedVerificationSelector
})


export default compose(connect(selector, actions))(MobileHeader)
