import React, { useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Select, Space } from 'antd'
let index = 0
const SearchSelect = ({ handleFocus, setEditingField, handleRowChange,
  list, value, keyName }) => {
  const [itemsOrigin, setItemsOrigin] = useState(list)
  const [items, setItems] = useState(list)
  const [name, setName] = useState('')
  const [newValue, setNewValue] = useState('')
  const inputRef = useRef(null)
  const selectRef = useRef(null)
  const onNameChange = (event) => {
    var nText = event.target.value
    var newItems = itemsOrigin.filter(item => item.toLowerCase().includes(nText.toLowerCase()))
    setItems(newItems)
    setName(event.target.value)

  }
  const addItem = (e) => {
    e.preventDefault()
    if (name.length > 0 && !items.includes(name)) {
      setItems([...itemsOrigin, name || `New item ${index++}`])
      setItemsOrigin([...itemsOrigin, name || `New item ${index++}`])
      setName('')
    }
  }

  // Filter `option.label` match the user type `input`
  const filterOption = ((input, option) => {
    setNewValue(input)
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  })

  return (
    <Select
      style={{
        width: '100%',
        color: 'white',
        background: 'transparent'
      }}
      showSearch
      value={value}
      ref={selectRef}
      filterOption={filterOption}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
      dropdownRender={(menu) => (
        <>
          {/* <div>
            <Input
              placeholder="New Item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>
          <div>
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add
            </Button>
          </div>
          <Divider
            style={{
              margin: '3px 0',
            }}
          /> */}
          {menu}
        </>
      )}
      onFocus={() => handleFocus()}
      onBlur={(ee) => {
        console.log("ee==in blue:", ee)
        if (newValue != '') {
          handleRowChange({ target: { value: newValue.toUpperCase(), name: keyName } })
        }
        setEditingField(null)
      }}
      onSelect={(e) => {
        setNewValue('')
        handleRowChange({ target: { value: e, name: keyName } })
        // if (selectRef.current) {
        //   selectRef.current.blur("done")
        // }
      }}
      options={items.map((item) => ({
        label: item,
        value: item,
      }))}
    />
  )
}
export default SearchSelect