import { createAction } from 'redux-actions'
import * as CONSTANTS from './constants'

//-----------stripe-----------------
export const createStripeCustomer = createAction(CONSTANTS.CREATE_STRIPE_CUSTOMER)
export const createStripeSubscription = createAction(CONSTANTS.CREATE_STRIPE_SUBSCRIPTION)
export const getSubscriptionInfo = createAction(CONSTANTS.GET_SUBSCRIPTION_INFO)
export const getSubscribeDetail = createAction(CONSTANTS.GET_SUBSCRIBE_DETAIL)
export const cardUpdateInit = createAction(CONSTANTS.CARD_UPDATE_INIT)
export const cardUpdateFinal = createAction(CONSTANTS.CARD_UPDATE_FINAL)
export const updateStripeSubscription = createAction(CONSTANTS.UPDATE_STRIPE_SUBSCRIPTION)
export const getStripeSubscriptions = createAction(CONSTANTS.GET_ALL_STRIPE_SUBSCRIPTIONS)
export const cancelStripeSubscription = createAction(CONSTANTS.CANCEL_STRIPE_SUBSCRIPTION)
export const getInvoices = createAction(CONSTANTS.GET_INVOICES)
export const getPaypalInvoices = createAction(CONSTANTS.GET_PAYPAL_INVOICES)
export const getAdminInvoices = createAction(CONSTANTS.GET_ADMIN_INVOICES)

//-----------paypal-----------------
export const createPaypalSubscription = createAction(CONSTANTS.CREATE_PAYPAL_SUBSCRIPTION)
export const updatePaypalSubscription = createAction(CONSTANTS.UPDATE_PAYPAL_SUBSCRIPTION)
export const getPaypalSubscriptionDetail = createAction(CONSTANTS.GET_PAYPAL_SUBSCRIPTION_DETAIL)
export const getPaypalSubscriptions = createAction(CONSTANTS.GET_ALL_PAYPAL_SUBSCRIPTIONS)
export const cancelPaypalSubscription = createAction(CONSTANTS.CANCEL_PAYPAL_SUBSCRIPTION)
