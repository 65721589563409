const authInfo = () => {
  const authStore = {
    tokens: JSON.parse(localStorage.getItem('trax_token')),
    user: JSON.parse(localStorage.getItem('trax_user')),
  }
  return authStore
}

const authClear = () => {
  localStorage.removeItem('trax_token')
  localStorage.removeItem('trax_user')
  localStorage.removeItem('MyGameTrax_selected_lottie')
}

const isCookie = () => {
  const cookie = JSON.parse(localStorage.getItem('MyGameTrax_cookie'))
  return cookie
}
export {
  authInfo,
  authClear,
  isCookie,
}