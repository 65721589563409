import React, { useState } from 'react'
import useStyles from './Form.styles'
import {
  Button,
  CircularProgress
} from '@material-ui/core'
import { CustomInput } from 'components/CustomInput'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from 'redux/modules/auth/actions'
import { CustomAlert } from 'components/CustomAlert'
import { useHistory } from 'react-router-dom'
import * as cx from 'classnames'

const Form = ({
  login,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValidText, setEmailValidText] = useState('')
  const [passwordValidText, setPasswordValidText] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)

  const handleLogin = () => {
    setEmailValidText('')
    setPasswordValidText('')
    setIsWaiting(true)
    login({
      body: { password, email },
      success: ({ data }) => {
        setTimeout(() => {
          setIsWaiting(false)
          if(data.user.verifyStatus){
            if (data.user.level == "1" || data.user.level == "0") {
              history.push('/app/users')
            } else {
              history.push('/app/sheets')
            }
          }else{
            history.push('/signin')
            setAlertText("Please verify email from the inbox")
            setInfoOpen(true)
          }
        }, 500)
      },
      fail: (err) => {
        setIsWaiting(false)
        if (err.status === 400) {
          const error = err.data.message
          setEmailValidText('')
          setPasswordValidText('')
          error.includes('email') && setEmailValidText(error.replace(`"email"`, 'Email'))
          error.includes('password') && setPasswordValidText(error.replace(`"password"`, 'Password'))
        } else {
          setAlertText(err.data.message)
          setAlertOpen(true)
        }
      }
    })
  }
  const handleForgetPassword = () => {
    history.push('/forgot-password')
  }
  const handleSignup = () => {
    history.push('/signup')
  }

  return (
    <>
      <div className={classes.root}>
        <div className={cx(classes.title, 'pb-30')}>Sign In</div>
        <CustomInput
          placeholder='E-Mail'
          onChange={value => setEmail(value)}
          hasError={emailValidText}
        />
        <div className='pb-5'></div>
        <CustomInput
          placeholder='Password'
          type='password'
          onChange={value => setPassword(value)}
          hasError={passwordValidText}
        />
        <div className='pb-10'></div>
        {!isWaiting ? <Button className={cx(classes.home_btn)}
          onClick={handleLogin}>Sign In</Button> 
          : <Button className={cx(classes.home_btn)} disabled>
          <CircularProgress size={24} className={classes.buttonProgress} /></Button>}
        <div className='pb-20'></div>
        <div className={classes.label4}>
          <span onClick={handleForgetPassword} >Forgot password?</span>
        </div>
        <div className={classes.label4}>
          <span onClick={handleSignup} >If you don't have account? Sign up</span>
        </div>
        {/* <div className={classes.label4}>
          New to MyGameTrax? <span className={classes.label3} onClick={handleSignup}>Get Free Trial</span>
        </div> */}
        <CustomAlert isOpen={alertOpen} type='error' text={alertText} onClose={() => setAlertOpen(false)} />
        <CustomAlert isOpen={infoOpen} type='info' text={alertText} onClose={() => setInfoOpen(false)} />
      </div>
    </>
  )
}

Form.propTypes = {
  login: PropTypes.func,
}

const actions = {
  login,
}

export default compose(connect(null, actions))(Form)
