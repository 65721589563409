import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import SideNav from 'containers/SideNav'
import {
    Container,
} from '@material-ui/core'
import useStyles from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import AppViews from '../../pages/AppViews'

export const AppLayout = () => {
    const location = useLocation()
    const history = useHistory()
    const paramList = location.pathname.split("/")
    var sideNav = ""
    if (paramList.length > 2) {
        sideNav = paramList[2]
    }
    const classes = useStyles()
    const [isLandingHeader, setIsLandingHeader] = useState(true)
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    const handleScroll = () => {
        const len = window.location.search.length
        const value = window.scrollY > 50 ? false : true
        len === 0 && setIsLandingHeader(value)
    }

    return (
        <>
            <Mobile>
                <MobileHeader isLandingPage={isLandingHeader} />
                <div className='home-applayout-section'>
                    <Container className={classes.root}>
                        <div className={classes.appContent}>
                            <AppViews />
                        </div>
                    </Container>
                </div>
            </Mobile>
            <Default>
                <Header isLandingPage={isLandingHeader} />
                <div className='home-applayout-section'>
                        {/* <SideNav path={sideNav} history={history} className={classes.sidenavA} /> */}
                        {/* <div className={classes.appContent}> */}
                            <AppViews />
                        {/* </div> */}
                </div>
            </Default>
        </>
    )
}

export default AppLayout