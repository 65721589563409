import { handleActions } from 'redux-actions'
import { requestSuccess, requestFail } from 'redux/api/request'
import * as CONSTANTS from './constants'

const getInitialState = () => {
  return {
    liveData: {},
    sheetName: "Untitled Sheet",
    liveDataByUser: [],
    params: {
      page: 1,
      limit: 10,
      totalPages: 1,
      totalResults: 1,
    },
    dropdownInput: {},
    status: 'INIT',
    error: null,
  }
}

export default handleActions({

  [CONSTANTS.CREATE_LIVEDATA]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.CREATE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    liveData: payload,
    sheetName: payload.docName
  }),
  [requestFail(CONSTANTS.CREATE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    error: payload,
  }),

  [CONSTANTS.UPDATE_LIVEDATA]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.UPDATE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    liveData: payload,
    sheetName: payload.docName
  }),
  [requestFail(CONSTANTS.UPDATE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    error: payload,
  }),

  [CONSTANTS.DELETE_LIVEDATA]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.DELETE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
  }),
  [requestFail(CONSTANTS.DELETE_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    error: payload,
  }),

  [CONSTANTS.GET_LIVEDATA]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.GET_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    liveData: payload,
    sheetName: payload.docName
  }),
  [requestFail(CONSTANTS.GET_LIVEDATA)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    liveData: {},
    sheetName: "Untitled Sheet",
    error: payload,
  }),

  [CONSTANTS.UPDATE_LOCAL_LIVEDATA]: (state, { payload }) => ({
    ...state,
    liveData:payload
  }),

  [CONSTANTS.CLEAR_LIVEDATA]: (state, { payload }) => ({
    ...state,
    liveData:{}
  }),

  [CONSTANTS.UPDATE_SHEET_NAME]: (state, { payload }) => ({
    ...state,
    sheetName: payload,
  }),

  [CONSTANTS.GET_LIVEDATA_BYUSER]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.GET_LIVEDATA_BYUSER)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    liveDataByUser: payload,
  }),
  [requestFail(CONSTANTS.GET_LIVEDATA_BYUSER)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    error: payload,
  }),

  [CONSTANTS.GET_DROPDOWNINPUT]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.GET_DROPDOWNINPUT)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    dropdownInput: payload,
  }),
  [requestFail(CONSTANTS.GET_DROPDOWNINPUT)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    dropdownInput: {},
    error: payload,
  }),

  [CONSTANTS.UPDATE_DROPDOWNINPUT]: (state, { payload }) => ({
    ...state,
    status: 'PENDING',
  }),
  [requestSuccess(CONSTANTS.UPDATE_DROPDOWNINPUT)]: (state, { payload }) => ({
    ...state,
    status: 'SUCCESS',
    dropdownInput: payload,
  }),
  [requestFail(CONSTANTS.UPDATE_DROPDOWNINPUT)]: (state, { payload }) => ({
    ...state,
    status: 'FAILED',
    error: payload,
  }),


}, getInitialState())
