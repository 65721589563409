import React, { useState, useMemo, useEffect } from 'react'
import {
    Grid,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { IoMdAlert } from 'react-icons/io'
import paymentAsset from 'assets/images/images/payment.png'
import paymentAsset2 from 'assets/images/images/payment02.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"
import { FiCreditCard } from 'react-icons/fi'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector, last4Selector, subscripetionPayPalInfoSelector }
    from 'redux/modules/subscribe/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import {
    createStripeSubscription,
    updateStripeSubscription,
    createPaypalSubscription,
    updatePaypalSubscription,
} from 'redux/modules/subscribe/actions'
import { refresh } from 'redux/modules/auth/actions'
import {
    getSubscriptionInfo, getSubscribeDetail, getPaypalSubscriptionDetail,
    cardUpdateInit, cardUpdateFinal, cancelStripeSubscription,
    cancelPaypalSubscription
} from 'redux/modules/subscribe/actions'
import { roles } from 'helpers/AppConfig'
import moment from 'moment'
import { Loader } from 'components/Loader'
import dashboard_a from 'assets/images/avatar/coache03.png'
import { useMediaQuery } from 'react-responsive'
import { CustomPaypalButton } from 'components/CustomPaypalButton'
import { setSubscribeOpen } from 'redux/modules/global/actions'

const featureContents = [
    {
        id: 0,
        title: 'Only Icons',
        text: 'Icon',
        subheader: 'Unlimited access to all icons.',
        ipad_subheader: 'All Icons unlimited',
        value: '$9',
        amount: '9',
        priceId: 'price_1IV1IyDVdnix3Irr5NiMXWeJ',
        // priceId: 'price_1IAu1oEVxlXovjp3K9r3pZmw', // test key
        planId: 'P-31502541BM074764RMZGHQNQ',
        content: [
            {
                title: 'Source file included',
                text: 'All icons can be downloaded with their own After Effects file.',
                ipad_text: 'Each icon has it own AE file'
            },
            {
                title: 'JSON file included',
                text: 'All icons can be downloaded as JSON or GIF.',
                ipad_text: 'Implement it where every you want'
            },
            {
                title: 'Lifetime use',
                text: 'You have lifetime rights for all downloaded icons.',
                ipad_text: 'You have lifetime rights to use'
            },
        ]
    },
    {
        id: 1,
        title: 'Only Illustrations',
        text: 'Illustration',
        subheader: 'Unlimited access to all illustrations.',
        ipad_subheader: 'All Illustrations unlimited',
        value: '$9',
        amount: '9',
        priceId: 'price_1IlcopDVdnix3IrrZmLwrnTw',
        planId: 'P-0PC61276K7027905YMBHIQTI',
        content: [
            {
                title: 'Source file included',
                text: 'All illustrations can be downloaded with their own After Effects file.',
                ipad_text: 'Each Illustration has its own AE file'
            },
            {
                title: 'JSON and GIF',
                text: 'All illustrations can be downloaded as JSON or GIF.',
                ipad_text: 'Implement it where every you want'
            },
            {
                title: 'Lifetime use',
                text: 'You have lifetime rights for all downloaded illustrations.',
                ipad_text: 'Use it for your Powerpoint'
            },
        ]
    },
    {
        id: 2,
        title: 'Icons & Illustrations',
        text: 'Icon and Illustration',
        subheader: 'Unlimited access to everything + SVG download',
        ipad_subheader: 'All Icons & Illustrations unlimited',
        value: '$15',
        amount: '15',
        priceId: 'price_1IV1IzDVdnix3IrrExRjWClP',
        planId: 'P-1AC91319L4909360YMBHIRFA',
        special: true,
        content: [
            {
                title: 'Package one and two',
                text: 'Everything from the packages Only Icons and Only Illustrations included.',
                ipad_text: 'Everything included'
            },
            {
                title: 'Illustrator files',
                text: 'All icons and illustrations can be downloaded as SVG.',
                ipad_text: 'SVG and Illustrator Files available'
            },
        ]
    },
]

const Subscriptions = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [subscriptionInfo, setSubscriptionInfo] = useState({})
    const [open, setOpen] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const isMobile = useMediaQuery({ maxWidth: 600 })

    useEffect(() => {
        console.log("props.current user:", props.currentUser.id)
        if (props.currentUser != null) {
            // props.setIsSubscribeLoading({ isLoading: true })
            props.getPaypalSubscriptionDetail({
                body: { 'userId': props.currentUser.id },
                success: (() => {
                    props.setIsSubscribeLoading({ isLoading: false })
                })
            })
        }
    }, [props.currentUser, location])

    useEffect(() => {
        setSubscriptionInfo(props.subscriptionPayPalInfo ?? {})
    }, [props.subscriptionPayPalInfo])

    const onCloseCancel = async () => {
        setOpenCancel(false)
    }

    const cancelSubscription = async (event) => {
        props.setIsSubscribeLoading({ isLoading: true })
        props.cancelPaypalSubscription({
            body: { userId: props.currentUser.id },
            success: () => {
                props.setIsSubscribeLoading({ isLoading: false })
                refreshUser()
            }
        })
    }

    const refreshUser = async () => {
        props.setIsSubscribeLoading({ isLoading: true })
        await props.refresh({
            success: ({ data }) => {
                props.setIsSubscribeLoading({ isLoading: false })
            },
        })
    }

    const handlePayPalClick = () => {
        setIsSubscribeLoading({ isLoading: true })
    }

    const handlePaypalSuccess = (data) => {
        paypalSubscription(data)
    }

    const handleCancelPaypal = () => {
        setAlertText('Paypal processing Canceled!')
        setAlertOpen(true)
        setIsSubscribeLoading({ isLoading: false })
    }

    const paypalSubscription = async (data) => {
        const { email } = props.currentUser
        const isForUpgrade = false
        const mode = isForUpgrade ? 'upgrade' : 'create'

        console.log("data after payment:", data)

        await props.createPaypalSubscription({
            body: {
                email,
                paypal_subscription_id: data.id,
                payer_id: data.subscriber.payer_id,
                startDate: data.start_time,
                current_period_start: data.update_time,
                // current_period_end: moment(data.billing_info.next_billing_time).add(1,'day'),
                current_period_end: data.billing_info.next_billing_time,
                licensePackageId: 1,
                status: data.status,
                mode,
            },
            success: ({ data }) => {
                refreshUser()
            },
            fail: (err) => {
                setAlertText(err.data.message)
                setAlertOpen(true)
                setIsSubscribeLoading({ isLoading: false })
            }
        })
    }

    return (
        <>
            {props.currentUser.role == roles[0] ?
                <div className={cx(classes.cardSummary, 'mt-40')}><Grid container>
                    <Grid item md={7} xs={12} className="between-start">
                        <div className={cx(classes.cardTitle002)}>Miss MyGameTrax?</div>
                        <div className={cx(classes.cardTitle002, 'pb-20')}>Get a subscription</div>
                        <div className={classes.managerPrice}>$750/YEARLY</div>
                        <div className={cx(classes.cardText, 'pb-10')}>You can create sheets and download them from the site</div>
                        <CustomPaypalButton
                            info={featureContents[0]}
                            onClick={handlePayPalClick}
                            onSuccess={handlePaypalSuccess}
                            onCancel={handleCancelPaypal}
                        />
                    </Grid>
                    <Grid item md={5} xs={12} className="center-center">
                        <img src={dashboard_a} alt=""
                            style={{ height: "192px" }} />
                    </Grid>
                </Grid></div>
                : <div className={cx('mt-40')}>
                    <div className={cx(classes.title, 'pb-10')}>My Subscription</div>
                    {props.isSubscribeLoading ? <div className='linear-height'>
                        <LinearProgress />
                        <div className={classes.loading}></div>
                    </div> : <div className='linear-height-border'>
                    </div>}
                    <Grid container>
                        <Grid item md={7} xs={12} className="between-start">
                        <div className={classes.managerCard}>
                        <div>
                            <div className={classes.managerTitle}>Subscription</div>
                            <div className={classes.managerDetail}>ID: <span>{subscriptionInfo.paypal_subscription_id}</span></div>
                            <div className={classes.managerDetail}>Start Date: <span>{subscriptionInfo.startDate == undefined ?
                                moment().format("MMM Do YYYY")
                                : moment(subscriptionInfo.startDate).format("MMM Do YYYY")}</span></div>
                            <div className={classes.managerDetail}>Next Charge: <span>{subscriptionInfo.current_period_end == undefined ?
                                moment().format("MMM Do YYYY")
                                : moment(subscriptionInfo.current_period_end).format("MMM Do YYYY")}</span></div>
                            <div className={classes.managerDetail}>Current Plan: <span>{
                                props.currentUser.role == roles[1]
                                    ? "$19.98 per month" : props.currentUser.role == roles[2] ?
                                        "$750.00 per year" : "Free"}</span></div>
                            <div className={classes.managerDetail}>Payment Method: <span>PayPal</span></div>
                        </div>
                        <div className={cx(isMobile ? 'center-column' : 'center-center','pb-10')}>
                            <Button className={cx(classes.home_btn_white)}
                                onClick={() => setOpenCancel(true)}>
                                Cancel Subscription</Button>
                        </div>
                        {/* <div className={classes.managerDetailUnder}
                            onClick={() => { history.push('invoices') }}>View payment history</div> */}
                        {/* <div className={classes.managerDetailUnder}>Update payment method</div> */}
                    </div>
                        </Grid>
                        <Grid item md={5} xs={12} className="center-center">
                            <img src={dashboard_a} alt=""
                                style={{ height: "192px" }} />
                        </Grid>
                    </Grid>
                    
                </div>}
            <Dialog open={openCancel} onClose={onCloseCancel}>
                <DialogContent >
                    <div className={cx('center-center pt-10 pb-20', classes.cardEnd)}>
                        Are you sure you want to cancel your subscription?
                    </div>
                    <Grid className={isMobile ? 'pb-10 center-column' : 'pb-10 center-center'}>
                        <Button className={cx(classes.home_btn_white, classes.widthJust)}
                            onClick={onCloseCancel} color='primary'>
                            Keep Plan
                        </Button>
                        <div className='w-10 pt-10'></div>
                        <Button className={cx(classes.home_btn, classes.widthJust)}
                            type="submit" onClick={() => {
                                onCloseCancel()
                                cancelSubscription()
                            }}
                            color='primary'>Cancel Subscription</Button>
                    </Grid>

                </DialogContent>
            </Dialog >
        </>
    )
}

Subscriptions.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    getSubscriptionInfo: PropTypes.func,
    getSubscribeDetail: PropTypes.func,
    cardUpdateInit: PropTypes.func,
    cardUpdateFinal: PropTypes.func,
    cancelStripeSubscription: PropTypes.func,
    cancelPaypalSubscription: PropTypes.func,
    refresh: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
    last4: PropTypes.any,
    createPaypalSubscription: PropTypes.func,
    setSubscribeOpen: PropTypes.func,
    getPaypalSubscriptionDetail: PropTypes.func,
    subscriptionPayPalInfo: PropTypes.any,
}

const actions = {
    setIsSubscribeLoading,
    getSubscriptionInfo,
    getSubscribeDetail,
    getPaypalSubscriptionDetail,
    cardUpdateInit,
    cardUpdateFinal,
    refresh,
    cancelStripeSubscription,
    createPaypalSubscription,
    cancelPaypalSubscription,
    setSubscribeOpen,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
    subscriptionInfo: subscriptionInfoSelector,
    subscriptionPayPalInfo: subscripetionPayPalInfoSelector,
    last4: last4Selector,
})

export default compose(connect(selector, actions))(Subscriptions)