import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { checkAccountVerification } from 'redux/modules/auth/actions'
import { setResetPasswordToken } from 'redux/modules/global/actions'
import PropTypes from 'prop-types'
import NewPasswordForm from '../../containers/NewPassword/NewPasswordForm/NewPasswordForm'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { CustomAlert } from 'components/CustomAlert'
import useStyles from '../../styles/Form.styles'

const Callback = ({
    checkAccountVerification,
    setResetPasswordToken,
}) => {
    const location = useLocation()
    const history = useHistory()
    const classes = useStyles()

    const [isLandingHeader, setIsLandingHeader] = useState(true)
    const [mode, setMode] = useState("password")
    const [isVerified, setIsVerified] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [alertText, setAlertText] = useState('')

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    const handleScroll = () => {
        const len = window.location.search.length
        const value = window.scrollY > 50 ? false : true
        len === 0 && setIsLandingHeader(value)
    }

    const handleSignIn = () => {
        history.push('/signin')
    }

    useEffect(() => {
        const doCheckAccountVerification = async (token) => {
            await checkAccountVerification({
                token,
                success: ({ data }) => {
                    setIsVerified(true)
                    setInfoOpen(true)
                    setIsProcessing(false)
                    setAlertText("Welcome. Email verified!")
                    setTimeout(() => {
                        history.push('/app/mysheet')
                      }, 2000)
                },
                fail: (err) => {
                    setIsVerified(false)
                    setIsProcessing(false)
                    //   history.push('/')
                }
            })
        }
        const init = () => {
            const params = new URLSearchParams(location.search)
            const arg_callbackMode = params.get('mode')
            if (arg_callbackMode === 'accountVerify') {
                const token = params.get('token')
                setMode('verify')
                setIsProcessing(true)
                doCheckAccountVerification(token)
            } else if (arg_callbackMode === 'forgotPassword') {
                const token = params.get('token')
                setMode("password")
                // setResetPasswordToken(token)
            }
        }
        init()
    }, [location, history, checkAccountVerification, setResetPasswordToken])

    return (
        <>
            <Mobile>
                <MobileHeader isLandingPage={isLandingHeader} />
                <div className='home-detail-section'>
                    {
                        mode == "password" ? <NewPasswordForm />
                            : isVerified ? <div>Verified Email</div>
                                : <div>Failed to verify email</div>}
                </div>
            </Mobile>

            <Default>
                <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    <div className='home-auth-setion'>
                        {
                            mode == "password" ? <NewPasswordForm />
                                : isVerified ? <div>
                                    <p className={classes.label5}>Verified Email Successfully</p>
                                </div>
                                    : isProcessing?<div className={classes.label5}>Verifying the email</div>:<div>
                                        <p className={classes.label5}>Failed to verify email</p>
                                        <div className={classes.label4}>
                                            <span onClick={handleSignIn} >
                                                Sign In</span>
                                        </div>
                                    </div>}
                    </div>
                    <CustomAlert isOpen={infoOpen} type='success' text={alertText} onClose={() => setInfoOpen(false)} />
                </BackgroundLine>
                <div className='fixed-bottom'>
                    <Footer />
                </div>
            </Default>
        </>
    )
}

Callback.propTypes = {
    checkAccountVerification: PropTypes.any,
    setResetPasswordToken: PropTypes.func,
}

const actions = {
    checkAccountVerification,
    setResetPasswordToken,
}

export default compose(connect(null, actions))(Callback)
