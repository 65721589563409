export const GET_LIVEDATA = 'GET_LIVEDATA'
export const CREATE_LIVEDATA = 'CREATE_LIVEDATA'
export const UPDATE_LIVEDATA = 'UPDATE_LIVEDATA'
export const UPDATE_LOCAL_LIVEDATA = 'UPDATE_LOCAL_LIVEDATA'
export const DELETE_LIVEDATA = 'DELETE_LIVEDATA'
export const UPDATE_SHEET_NAME = 'UPDATE_SHEET_NAME'
export const CLEAR_LIVEDATA = 'CLEAR_LIVEDATA'
export const GET_LIVEDATA_BYUSER = 'GET_LIVEDATA_BYUSER'

export const GET_DROPDOWNINPUT = 'GET_DROPDOWNINPUT'
export const UPDATE_DROPDOWNINPUT = 'UPDATE_DROPDOWNINPUT'
export const DELETE_DROPDOWNINPUT = 'DELETE_DROPDOWNINPUT'