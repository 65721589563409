import React, { useState } from 'react'
import {
    Container,
    Button,
    CircularProgress,
    LinearProgress,
    Grid
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { refresh } from 'redux/modules/auth/actions'
import { exchangePassword } from 'redux/modules/auth/actions'
import { AiOutlineEye } from 'react-icons/ai'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { CustomAlert } from 'components/CustomAlert'

const ChangePassword = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const [isWaiting, setIsWaiting] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [alertMode, setAlertMode] = useState('error')
    const [showPass, setShowPass] = useState(false)
    const [sheetName, setSheetName] = useState('Untitled Sheet')

    const [input, setInput] = useState({
        currentPassword: '',
        password: '',
        confirmPassword: ''
    })

    const [error, setError] = useState({
        currentPassword: '',
        password: '',
        confirmPassword: ''
    })

    const onInputChange = e => {
        const { name, value } = e.target
        setInput(prev => ({
            ...prev,
            [name]: value
        }))
        validateInput(e)
    }

    const validateInput = e => {
        let { name, value } = e.target

        console.log("inputed value==0099:", name, value)

        setError(prev => {
            const stateObj = { ...prev, [name]: "" }

            switch (name) {
                case "currentPassword":
                    if (!value) {
                        stateObj[name] = "Please enter current password."
                    }
                    break
                case "password":
                    if (!value) {
                        // stateObj[name] = "Please enter Password."
                    } else if (value.length < 8) {
                        stateObj[name] = "Password lenght needs to be over 8"
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match."
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword
                    }
                    break

                case "confirmPassword":
                    if (!value) {
                        if (input.password != '') {
                            stateObj[name] = "Please enter Confirm Password."
                        }
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match."
                    }
                    break

                default:
                    break
            }

            return stateObj
        })
    }

    const handleSavePassword = async (event) => {
        event.preventDefault()
        console.log("input.password==0:", input.password)
        if (input.currentPassword.length < 8) {
            setAlertOpen(true)
            setAlertText("Please input current password")
            setAlertMode('error')
        } else if (input.password.length > 8 && input.password == input.confirmPassword) {
            setIsWaiting(true)
            await props.exchangePassword({
                body: {
                    email: props.currentUser.email,
                    currentPassword: input.currentPassword,
                    newPassword: input.password
                },
                success: ({ data }) => {
                    console.log("set password success:", data)
                    setIsWaiting(false)
                    setAlertOpen(true)
                    setAlertText('Changed Password Successfully!')
                    setAlertMode('success')
                },
                fail: (err) => {
                    console.log("set passworderr:", err)
                    console.log("set passworderr.message:", err.message)
                    setAlertOpen(true)
                    setIsWaiting(false)
                    if (err.message == undefined) {
                        setAlertText("Please re-enter your current password and try again. Current password is invalid.")
                    } else {
                        setAlertText(err.message)
                    }
                    setAlertMode('error')
                }
            })
        } else {
            setAlertOpen(true)
            setAlertText("New password needs to include 5 characters at least")
            setAlertMode('error')
        }
    }

    const handleEditName = (e) => {
        console.log("clicked handleEditName", e.target.value)
        setSheetName(e.target.value)
    }

    const handleBlueName = (e) => {
        if (sheetName.trim() == "") {
            setSheetName("Untitled Sheet")
        } else {
            console.log("detect handleblue name:", sheetName)
        }
    }


    return (
        <>
            <div>
                <div className={cx(classes.title, 'pb-10 pt-40')}>Profile</div>
                {props.isSubscribeLoading ? <div className='linear-height'>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <div className='linear-height-border'>
                </div>}
                <div className='pb-10'></div>
                <div className={classes.root}>
                    <Grid container spacing={0} className={cx('center-start','pb-30')}>
                        <Grid item xs={3} >
                            <div>Email:</div>
                        </Grid>
                        <Grid item xs={9} >
                                <div className="sheet-name-container">
                                    <input
                                        type="text"
                                        className="sheet-name-input"
                                        value={sheetName}
                                        onChange={handleEditName}
                                        onBlur={() => handleBlueName(null)}
                                        onClick={(e) => e.target.select()}
                                    />
                                </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={0} className={cx('center-start','pb-30')}>
                        <Grid item xs={3} >
                            <div>Name:</div>
                        </Grid>
                        <Grid item xs={9} >
                                <div className="sheet-name-container">
                                    <input
                                        type="text"
                                        className="sheet-name-input"
                                        value={sheetName}
                                        onChange={handleEditName}
                                        onBlur={() => handleBlueName(null)}
                                        onClick={(e) => e.target.select()}
                                    />
                                </div>
                        </Grid>
                    </Grid>
                </div>
                <CustomAlert
                    isOpen={alertOpen}
                    type={alertMode}
                    text={alertText}
                    onClose={() => setAlertOpen(false)}
                />
            </div>
        </>
    )
}

ChangePassword.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    refresh: PropTypes.func,
    exchangePassword: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
}

const actions = {
    setIsSubscribeLoading,
    refresh,
    exchangePassword
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
})

export default compose(connect(selector, actions))(ChangePassword)