import React, { useEffect } from "react"
import { Route, Switch, Redirect, withRouter } from "react-router-dom"
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import AppLayout from "./app-layout"
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'helpers/AppConfig'

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
    console.log("isAuthenticated:",isAuthenticated)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated!=null ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}

export const Views = (props) => {

    const { location } = props

    const { currentUser } = props

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={APP_PREFIX_PATH} />
            </Route>
            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={currentUser}>
                <AppLayout location={location}/>
            </RouteInterceptor>
        </Switch>
    )
}


Views.propTypes = {
    currentUser: PropTypes.any,
  }
  
  const actions = {
  }
  
  const selector = createStructuredSelector({
    currentUser: currentUserSelector,
  })
  
export default compose(connect(selector, actions))(Views)
