import * as cx from 'classnames'
import useStyles from './styles'
import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Container, Grid } from '@material-ui/core'
import 'react-modal-video/scss/modal-video.scss'
import { useMediaQuery } from 'react-responsive'
const About = () => {
     const classes = useStyles()
     const [isLandingHeader, setIsLandingHeader] = useState(true)
     const isMobile = useMediaQuery({ maxWidth: 600 })
     useEffect(() => {
          window.addEventListener('scroll', handleScroll)
     })

     const handleScroll = () => {
          const len = window.location.search.length
          const value = window.scrollY > 50 ? false : true
          len === 0 && setIsLandingHeader(value)
     }

     const webContent = () => {
          return <div className='home-pattern-section'>
               <Container className='term-policy'><BackgroundLine>
                    <div className={classes.desc1}>
                         <div className={classes.title0}>About Us</div>
                         <div><br /></div>
                         <div className={classes.title2}>GameTrax: Revolutionizing Football Game Management</div>
                         <div>
                              &nbsp;&nbsp;Welcome to GameTrax, the innovative web app designed by coaches for coaches.
                              As a dedicated football coach myself, I spent over two years developing GameTrax
                              to transform the way football games are managed and analyzed. GameTrax is your
                              ultimate tool for live stats and data tracking, ensuring you're always one step ahead in the game.
                         </div>
                         <div className={classes.title2}>Our Story</div>
                         <div>&nbsp;&nbsp;Born from the need for efficiency and precision, GameTrax was developed to address the real
                              challenges faced by football coaches. Managing a 6A football program, I realized the immense
                              time and effort spent on repetitive data entry and post-game analysis. GameTrax was created
                              to alleviate these burdens, allowing coaches to focus on what truly matters – the game.
                         </div>
                         <div className={classes.title2}>Our Product</div>
                         <div>&nbsp;&nbsp;GameTrax is not just a stat tracker; it's your own Quality Control Coach. With live stat tracking,
                              you can monitor individual performances and team dynamics as the action unfolds.
                              Our app's compatibility with HUDL means that your game data can be uploaded immediately
                              after the game, saving you precious hours of film tagging and data entry.
                         </div>
                         <div className={classes.title2}>Key features of GameTrax include:</div>
                         <ul>
                              <li>Live stat tracking for real-time game analysis</li>
                              <li>Compatibility with HUDL for seamless data upload</li>
                              <li>Insightful analytics to identify your best plays and most successful formations</li>
                              <li>Time-saving functionality, eliminating redundant data entry</li>
                         </ul>
                         <div className={classes.title2}>Join the GameTrax Community</div>
                         <div>
                              &nbsp;&nbsp;As we continue to refine and enhance GameTrax, your feedback is invaluable. Currently in beta,
                              GameTrax is a growing project where your suggestions can shape the future of football game management.
                              Join us in revolutionizing the way football is coached and played.
                              Stay ahead of the game with GameTrax – because every play counts.
                         </div>

                         <div style={{height: isMobile?'250px':"0px"}}/>
                    </div>
               </BackgroundLine>

               </Container>
          </div>
     }

     return <>
          <Mobile>
               <BackgroundLine>
                    <MobileHeader isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <MobileFooter />
               </BackgroundLine>
          </Mobile>
          <Default>
               <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <Footer />
               </BackgroundLine>
          </Default>

     </>
}
export default About