import React, { useState, useMemo, useEffect } from 'react'
import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'

import priceTop from 'assets/images/images/price_top.png'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'

import {
    setSuccessOpen,
    setIsSubscribeLoading,
} from 'redux/modules/global/actions'
import {
    createStripeSubscription,
    updateStripeSubscription,
    createPaypalSubscription,
    updatePaypalSubscription,
} from 'redux/modules/subscribe/actions'
import { createStructuredSelector } from 'reselect'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector }
    from 'redux/modules/subscribe/selectors'
import { getSubscriptionInfo } from 'redux/modules/subscribe/actions'
import { refresh } from 'redux/modules/auth/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { authInfo, authClear } from 'helpers/localCheck'
import { roles } from 'helpers/AppConfig'
import { CustomAlert } from 'components/CustomAlert'
import moment from 'moment'

const actionType = ['create', 'upgrade', 'downgrade']

const SubscriptionPlans = ({
    setIsSubscribeLoading,
    createStripeSubscription,
    refresh,
    isSubscribeLoading,
    currentUser,
    getSubscriptionInfo,
    subscriptionInfo
}) => {
    const classes = useStyles()
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [alertMode, setAlertMode] = useState('error')
    const [currentSubscription, setCurrentSubscription] = useState({})

    useEffect(() => {
        setIsSubscribeLoading({ isLoading: true })
        if (currentUser != null) {
            getSubscriptionInfo({ 
                body: { 'userId': currentUser.id },
                success: ({ data }) => {
                    setIsSubscribeLoading({ isLoading: false })
                }, })
        }
    }, [currentUser])

    useEffect(() => {
        setCurrentSubscription(subscriptionInfo ?? {})
        if (subscriptionInfo != null)
            if (subscriptionInfo.planType != undefined) {
                setIsSubscribeLoading({ isLoading: false })
            }
            else {
                setIsSubscribeLoading({ isLoading: false })
            }
    }, [subscriptionInfo])

    const refreshUser = async () => {
        await refresh({
            success: ({ data }) => {
                setIsSubscribeLoading({ isLoading: false })
            },
        })
    }

    const clickPlans = async (index, isCreate) => {
        setIsSubscribeLoading({ isLoading: true })
        const licensePackageId = index + 1
        const { email, paymentMethodId } = authInfo().user
        let mode = 'create'
        const name = email.split("@")[0]

        const subscriptionId = currentSubscription.subscriptionId ?? ""
        let alertContent = ""

        if (isCreate == actionType[0]) {
            mode = 'create'
            alertContent = "Created a subscription successfully"
        } else if (isCreate == actionType[1]) {
            mode = 'upgrade'
            alertContent = "Upgraded a subscription successfully"
        } if (isCreate == actionType[2]) {
            mode = 'downgrade'
            alertContent = "Registered the downgrading action successfully"
        }

        await createStripeSubscription({
            body: { email, name, paymentMethodId, licensePackageId, subscriptionId, mode },
            success: ({ data }) => {
                setIsSubscribeLoading({ isLoading: false })
                console.log("subscription: success:", data)
                setAlertMode('success')
                setAlertText(alertContent)
                refreshUser()
            },
            fail: ({ data }) => {
                console.log("subscription: fail:", data)
                setAlertText(data.message)
                setAlertOpen(true)
                setIsSubscribeLoading({ isLoading: false })
            }
        })
    }

    return (
        <>
            <div className={classes.planCover}>
                <div>
                    <div className={classes.title}>Buy a New Plan</div>
                    <div className={classes.subTitle}>Select subscription plan</div>
                    {
                        isSubscribeLoading ? <div>
                            <LinearProgress />
                            <div className={classes.loading}></div>
                        </div> : <hr className={classes.subDivider}></hr>}
                    <div className={classes.subContent}>You use a credit card on file to update the subscription.</div>
                    <div className='flex-wrap'>
                        <div className={classes.managerCard}>
                            <div className='center-center flex-column'>
                                <div className={classes.managerName}>MONTHLY</div>
                                <div className={classes.managerSubPrice}>&nbsp;</div>
                            </div>
                            <div className={classes.managerPrice}>$19.98</div>
                            <div className={classes.content}>Billed Monthly.<br />
                                30-day money-back guarantee.</div>
                            {/* <div className={classes.unButton}>Current Plan</div> */}
                            {currentUser.role==roles[0]?
                                <Button className={classes.home_btn}
                                onClick={() => { clickPlans(0, actionType[0]) }}>Get Start&nbsp;<FaArrowRight />
                            </Button>
                            :currentSubscription.planType == roles[1] ?
                                <div className={classes.unButton}>Current Plan</div>
                                : currentSubscription.planType == roles[0] ? <Button className={classes.home_btn}
                                    onClick={() => { clickPlans(0, actionType[0]) }}>Get Start&nbsp;<FaArrowRight />
                                </Button>
                                    : currentSubscription.scheduleStart != null ?
                                        <div className='center-column'>
                                            <div className={classes.unButton}>Next Plan</div>
                                            <div>start on {moment.unix(subscriptionInfo.scheduleStart)
                                            .format("MMM Do YYYY")}</div>
                                        </div>
                                        : <Button className={classes.home_btn} onClick={() => {
                                            clickPlans(0, actionType[2])
                                        }}>Downgrade</Button>}
                        </div>
                        <div className='w-40'></div>
                        <div className={classes.managerCard}>
                            <div className={classes.priceTop}>
                                <img src={priceTop} alt="" />
                            </div>
                            <div className='center-center flex-column'>
                                <div className={classes.managerName}>ANNUAL</div>
                                <div className={classes.managerSubPrice}>(Save 33%)</div></div>
                            <div className={classes.managerPrice}>$158.98</div>
                            <div className={classes.content}>Billed Annually.<br />
                                30-day money-back guarantee.</div>
                            {currentUser.role==roles[0]?
                                <Button className={classes.home_btn}
                                onClick={() => { clickPlans(1, actionType[0]) }}>Get Start&nbsp;<FaArrowRight />
                            </Button>
                            :currentSubscription.planType == roles[2] ?
                                <div className={classes.unButton}>Current Plan</div>
                                : currentSubscription.planType == roles[0] ? <Button className={classes.home_btn}
                                    onClick={() => { clickPlans(1, actionType[0]) }}>Get Start&nbsp;<FaArrowRight />
                                </Button> : <Button className={classes.home_btn} onClick={() => {
                                    clickPlans(1, actionType[1])
                                }}>Upgrade</Button>}
                        </div>
                    </div>
                </div>
                <div className={isSubscribeLoading ? classes.disablePlan : ""}></div>
                <CustomAlert
                    isOpen={alertOpen}
                    type={alertMode}
                    text={alertText}
                    onClose={() => setAlertOpen(false)}
                />
            </div>
        </>
    )
}

SubscriptionPlans.propTypes = {
    setIsSubscribeLoading: PropTypes.func,
    createStripeSubscription: PropTypes.func,
    updateStripeSubscription: PropTypes.func,
    createPaypalSubscription: PropTypes.func,
    updatePaypalSubscription: PropTypes.func,
    refresh: PropTypes.func,
    onClickSubscribe: PropTypes.func,
    isValid: PropTypes.bool,
    isSubscribeLoading: PropTypes.any,
    currentUser: PropTypes.any,
    getSubscriptionInfo: PropTypes.func,
}
const actions = {
    setSuccessOpen,
    setIsSubscribeLoading,
    createStripeSubscription,
    updateStripeSubscription,
    createPaypalSubscription,
    updatePaypalSubscription,
    refresh,
    getSubscriptionInfo,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    subscriptionInfo: subscriptionInfoSelector,
    currentUser: currentUserSelector,
})

export default compose(connect(selector, actions))(SubscriptionPlans)