import useStyles from './styles'
import React, { useState, useEffect } from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import { Header, MobileHeader } from 'containers/Header'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { Container, Grid } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
const Policy = () => {
     const classes = useStyles()
     const [isLandingHeader, setIsLandingHeader] = useState(true)
     const isMobile = useMediaQuery({ maxWidth: 600 })
     useEffect(() => {
          window.addEventListener('scroll', handleScroll)
     })

     const handleScroll = () => {
          const len = window.location.search.length
          const value = window.scrollY > 50 ? false : true
          len === 0 && setIsLandingHeader(value)
     }

     const webContent = () => {
          return <div className='home-pattern-section'>
               <Container className='term-policy'><BackgroundLine>
                    <div className={classes.desc1}>
                         <div className={classes.title0}>Privacy Policy</div>
                         <div>Game Trax Privacy Policy</div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                         <div><br/></div>
                    </div>
               </BackgroundLine>

               </Container>
          </div>
     }

     return <>
          <Mobile>
               <BackgroundLine>
                    <MobileHeader isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <MobileFooter />
               </BackgroundLine>
          </Mobile>
          <Default>
               <BackgroundLine>
                    <Header isLandingPage={isLandingHeader} />
                    {
                         webContent()
                    }
                    <Footer />
               </BackgroundLine>
          </Default>

     </>
}
export default Policy