import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    '@media only screen and (max-width: 600px)': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }
  },
  videoCover:{
    width:'100%',
    height:'320px',
    position: 'relative',
    '@media only screen and (max-width: 1200px)': {
      height:'250px',
    },
  },
  logoVideo:{
    padding:'20px 0px',
  },
  videoBox:{
    width:'100%',
    height:'100%',
    background: '#474242',
    border: '3px solid #009348',
    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.25)',
    borderRadius: '15px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    color: '#009348',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  videoPlay:{
    position:'absolute',
    top:0,
    width:'100%',
    height:'100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center'
  },
  logoImg:{
    width:'150px',
    '@media only screen and (max-width: 1200px)': {
      width:'100px'
    },
  },
  videoArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    width: '100%',
    height: '360px',
    marginTop: theme.spacing(2),
    borderRadius: '4px',
    userSelect: 'none',
    '@media only screen and (max-width: 600px)': {
      height: '210px',
      marginTop: theme.spacing(0),
    },
    '@media only screen and (min-width: 601px) and (max-width: 1024px)': {
      height: '226px',
      width: '352px',
    }
  }
}))
