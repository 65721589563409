import React, { useState, useMemo, useEffect } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import {
    Chip,
    Button,
    TablePagination,
    ButtonGroup,
    LinearProgress,
    IconButton
} from '@material-ui/core'
import { Space, Table, Tag, Popconfirm } from 'antd'
import useStyles from './styles'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import { FaRegCopy } from 'react-icons/fa'
import { AiFillWindows } from 'react-icons/ai'
import { AiFillApple } from 'react-icons/ai'
import { SiLinux } from 'react-icons/si'
import { IoIosArrowForward } from 'react-icons/io'
import { AiOutlineDownload } from 'react-icons/ai'
import paymentAsset from 'assets/images/images/payment.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import { FiCreditCard } from 'react-icons/fi'
import { ImBin } from 'react-icons/im'
import { BsSearch } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { getInvoices } from 'redux/modules/subscribe/actions'
import { refresh } from 'redux/modules/auth/actions'
import { getUsers, deleteUser, updateUser, requestAdmin } from 'redux/modules/user/actions'
import { roles } from 'helpers/AppConfig'
import moment from 'moment'
import { Loader } from 'components/Loader'
import { authInfo, authClear } from 'helpers/localCheck'
import { CustomAlert } from 'components/CustomAlert'

const Admins = (props) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [alertMode, setAlertMode] = useState('error')
    const [userList, setUserList] = useState({})
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 100, { value: -1, label: 'All' }])
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [isRequesting, setIsRequesting] = useState(false)

    useEffect(() => {
        if (props.currentUser != null) {
            props.getUsers({
                params: {
                    email: searchValue ? searchValue : '',
                    level: '1',
                    sortBy: 'asc',
                    limit,
                    page,
                },
                success: ({ data }) => {
                    console.log("data.results:", data.results)
                    setUserList(data)
                    setRowsPerPageOptions([10, 25, 100, { value: data.totalResults, label: 'All' }])
                },
                fail: (err) => {
                    //console.log(err)
                }
            })
        }
    }, [props.currentUser])

    const handleChangePage = (e, value) => {
        setPage(value + 1)
    }

    const handleChangeRowPerPage = (e) => {
        const value = e.target.value
        setLimit(value)
    }

    const handleClickDelete = async (userInfo) => {
        setIsLoading(true)
        await props.deleteUser({
            id: userInfo.id,
            success: async ({ data }) => {
                setIsLoading(false)
                await getUsersInfo(page, limit)
            },
            fail: (err) => {
                setIsLoading(false)
            }
        })
    }

    const handleClickDisable = async (userInfo) => {
        setIsLoading(true)
        console.log("clicked disable", userInfo.status)
        await props.updateUser({
            id: userInfo.id,
            body: { status: userInfo.status == 'active' ? 'disable' : 'active' },
            success: async ({ data }) => {
                setIsLoading(false)
                await getUsersInfo(page, limit)
            },
            fail: (err) => {
                setIsLoading(false)
            }
        })
    }

    const getUsersInfo = async (page, limit) => {
        await props.getUsers({
            params: {
                email: searchValue ? searchValue : '',
                level: '1',
                sortBy: 'asc',
                limit,
                page,
            },
            success: ({ data }) => {
                setUserList(data)
            },
            fail: (err) => {
                //console.log(err)
            }
        })
    }

    const submitForm = (e) => {
        e.preventDefault()
        console.log(e)
        console.log(e.target.newAdmin.value)
        
        props.requestAdmin({
            body:{
                email:e.target.newAdmin.value,
                level:'1',
                postCode:"123",
                paymentMethodId:"123"
            },
            success: async ({data})=>{
                setAlertText("Requested successfully")
                setAlertOpen(true)
                setAlertMode('success')
                setIsRequesting(false)
                await getUsersInfo(page, limit)
            },
            fail: (err) => {
                setIsSubscribeLoading({ isLoading: false })
                const error = err.data.message??""
                setAlertText(error)
                setAlertMode('error')
                setAlertOpen(true)
            }
        })
    }

    const datatableColums = [
        {
            title:"ID",
            key:"index",
            render : (text, record, index) => (page - 1) * 10 + index+1,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: 'Status',
            dataIndex: 'verifyStatus',
            key: 'verifyStatus',
            render : (_, record) => <div className={record.verifyStatus?"text-active":"text-pending"}>
                {record.verifyStatus?"Active":"Pending"}</div>,
        },
        {
            name: 'Joined at',
            title: 'Joined at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => <div>
                {moment(record.createdAt).format('MMM DD YYYY')}
            </div>
        },
        {
            name: 'Action',
            title: 'Action',
            render: (_, record) => {
                console.log("table record:", record)
                return <div className='center-center'>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleClickDelete(record)}>
                        <a className='action-remove' ><ImBin /></a>
                    </Popconfirm>
                </div>
            }
        }
    ]

    return (
        <>
            <div>
                <div className='center-between'>
                    <div className={cx(classes.title, 'pb-10')}>Admins</div>
                    <div className='searchBox position-relative'>{isRequesting ?
                        <form className='center-center' onSubmit={(e)=>submitForm(e)}>
                            <input className='searchInputField mr-10' name='newAdmin' type="email" required/>
                            <Button className='home_btn mr-10' type='submit'>Request</Button>
                            <div className='w-10'></div>
                            <Button className='home_btn_cancel'
                            onClick={()=>setIsRequesting(false)}>Cancel</Button></form>
                        : <Button className='home_btn' 
                        onClick={()=>setIsRequesting(true)}>Invite a new admin</Button>}</div>
                </div>
                {props.isSubscribeLoading ? <div>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <hr className={classes.subDivider}></hr>}
                <div className='table-responsive'>
                <Table columns={datatableColums} dataSource={userList.results} pagination={false} className="antd-Tbl"/>
                </div>
                <TablePagination
                    component='div'
                    count={userList.totalResults}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={handleChangeRowPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
                <CustomAlert isOpen={alertOpen} type={alertMode} text={alertText} onClose={() => setAlertOpen(false)} />
            </div>
        </>
    )
}

Admins.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    refresh: PropTypes.func,
    getUsers: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
    requestAdmin: PropTypes.func
}

const actions = {
    setIsSubscribeLoading,
    getUsers,
    refresh,
    deleteUser,
    updateUser,
    requestAdmin,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
})

export default compose(connect(selector, actions))(Admins)