import { all } from 'redux-saga/effects'
import auth from './auth'
import subscribe from './subscribe'
import user from './user'
import global from './global'
import sheet from './sheet'


export default function* rootSaga() {
  yield all([
    auth(),
    subscribe(),
    user(),
    global(),
    sheet()
  ])
}
