import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { TypeAnimation } from 'react-type-animation'

const status = [true, true, true, true]
let timerId = null
let lottieIndex = 0

const DetailDescription = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [playStatus, setPlayStatus] = useState(status)
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
    return () => {
      timerId && clearInterval(timerId)
      timerId = null
      lottieIndex = 0

      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    document.getElementById('detail-description').addEventListener('transitionend', () => {
      if (!timerId) { timerId = setInterval(lottieTime, 2000) }
    })
    const lottieTime = () => {
      lottieIndex > 3 && clearInterval(timerId)
      const tmpStatus = status.slice()
      tmpStatus[lottieIndex] = false
      setPlayStatus(tmpStatus)
      lottieIndex++
    }
  })

  const handleMoveToStart = () => {
    props.moveToStart()
  }

  return (
    <div>
      {!isMobile && <h1 className={classes.title0}>
        Coach's Next-Gen <br /> Analytics Tools
      </h1>}
      <div>
        <span className={classes.title2}>
          For &nbsp;
        </span>
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed out once, initially
            'Real Time Analysis',
            1000, // wait 1s before replacing "Mice" with "Hamsters"
            'Compatible with HUDL',
            1000,
            'Identify Best Players',
            1000,
          ]}
          wrapper="span"
          speed={30}
          style={{
            fontSize: '30px', backgroundColor: 'grey',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '10px',
            color: 'white', display: 'inline-block'
          }}
          repeat={Infinity}
        />
      </div>

      <div className={classes.title3}>Welcome to GameTrax</div>

      {/* <Button className={classes.home_btn} onClick={()=>handleMoveToStart()}>
        {"7-DAY FREE TRIAL"}&nbsp;<HiOutlineArrowRight /></Button> */}
    </div>
  )
}

export default DetailDescription
