import React, { useState,useRef } from 'react'
import { MdDeleteOutline } from "react-icons/md"

function EditableRow({ rowNumber, row, isLastRow, dataRows,
    handleRowChange, handleRemoveRow, columnsWithSelect,
    addNewRow, handleSelectRow }) {
    // State to track the currently editing field for background color change
    const [editingField, setEditingField] = useState(null)

    const handleFocus = (key) => {
        setEditingField(key)
        if (isLastRow) {
            addNewRow()
        }
    }

    // Helper function to render the correct input type
    const renderInput = (key, value, row) => {
        if (columnsWithSelect[key]) { // If the column has specified select options
            if(key=='OPP TEAM' && rowNumber!=1 && rowNumber!=2){
                return <input
                    type="text"
                    autoComplete='off'
                    name={key}
                    value={value}
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e)}
                />
            }
            return (
                <select
                    name={key}
                    value={value}
                    style={{
                        width: '100%',
                        border: 'none', // Set border to none
                        outline: 'none',
                    }} // Set width to 100%
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e)}
                >
                    <option key="empty" value=""></option>
                    {columnsWithSelect[key].map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            )
        } else { // Default to text input
            return (
                <input
                    type="text"
                    autoComplete='off'
                    name={key}
                    value={value}
                    onFocus={() => handleFocus(key)}
                    onBlur={() => setEditingField(null)}
                    onChange={(e) => handleRowChange(row.id, e)}
                />
            )
        }
    }

    const timerIdRef = useRef(null)
    return (
        <tr className={row.isSelected ? 'selectedRow' : ''}
            onMouseDown={() => {
                handleSelectRow(row.id)
            }}
            onMouseUp={() => {
                console.log("called the cliear timer function")
                clearTimeout(timerIdRef.current)
            }}
        >
            {Object.keys(row).map((key, index) => {
                if (key === 'id') {
                    return <></>
                    return (<td
                        key={index}
                        style={{
                            backgroundColor: editingField === key ? '#a0a0a0' : 'transparent', cursor: 'pointer'
                        }}
                    >
                        {rowNumber}
                    </td>)
                }
                else if (key !== 'isSelected')
                    return (
                        <td
                            key={index}
                            style={{
                                backgroundColor: editingField === key ? '#a0a0a0' : 'transparent',
                            }}
                        >
                            {renderInput(key, row[key],row)}
                        </td>
                    )
            })}
            <td>
                <button onClick={() => handleRemoveRow(row.id)}><MdDeleteOutline /></button>
            </td>
        </tr>
    )
}

export default EditableRow