export const downloadStringAsCsv = (dataString, filename) => {
    // Create a Blob with your data string and the correct MIME type
    const blob = new Blob([dataString], { type: 'text/csvcharset=utf-8' })
  
    // Create a link element
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
  
    // Append link to the body, trigger the click to download, and then remove the link
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
