import React from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { ContentTwo } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import { VideoPlayer } from 'components/VideoPlayer'
import live_data from 'assets/images/features/live_data.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const FeatureOne = (props) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
          <img className={classes.logoImg} src={live_data} alt='' />
          </Grid>
          <Grid item xs={12}>
          <ContentTwo index={0}/>
          </Grid>
        </Mobile>

        <Default>
          <div className="center-between">
            <Grid item xs={6}>
            <ContentTwo index={0}/>
            </Grid>
            <Grid item xs={6} className="center-center">
              <img className={classes.logoImg} src={live_data} alt='' />
            </Grid>
          </div>
        </Default>
      </Grid>

    </Container>
  )
}

export default FeatureOne
