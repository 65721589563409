import { RxDashboard } from 'react-icons/rx'
import { AiOutlineUser } from 'react-icons/ai'
import { TbFileDollar } from 'react-icons/tb'
import { FiKey } from 'react-icons/fi'
import { FiLogOut } from 'react-icons/fi'

const NavList = [
    {
        label: "New Sheet", icon: RxDashboard, path: '/app/mysheet', active: false
    },
    {
        label: "All Sheets", icon: AiOutlineUser, path: '/app/sheets', active: false
    },
    {
        label: "Subscription", icon: TbFileDollar, path: '/app/subscription', active: false
    },
    {
        label: "Change Password", icon: FiKey, path: '/app/changepassword', active: true
    },
    {
        label: "Logout", icon: FiLogOut, path: '/signin', active: false
    },
  ]

  export default NavList