import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
  title: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '40px',
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '32px',
    }
  },
  subDivider: {
    borderTop: '1px solid lightgrey'
  },
  invoiceTbl: {
    backgroundColor:'transparent'
  }
}))