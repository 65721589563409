import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Link,
} from '@material-ui/core'
import useStyles from './NavMenu.styles.js'
import logo from 'assets/images/logo/Group.png'
import { MobileSearchBar } from 'containers/SearchBar'
import { CustomButton } from 'components/CustomButton'
import {
  setSuccessOpen,
  clearGlobal,
} from 'redux/modules/global/actions'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from 'redux/modules/auth/actions'
import { authInfo, authClear } from 'helpers/localCheck'
import { Button } from '@material-ui/core'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { mongoObjectId } from '../../helpers/liveData'
import NavList from '../../helpers/NavList'
import NavListAdmin from '../../helpers/NavListAdmin'
import * as cx from 'classnames'
import Icon from "components/Icon"
import mobile_logo from 'assets/images/logo/mobile_logo.svg'
import white_sheet_logo from 'assets/images/images/logo_video.png'

const content = [
  { text: 'About', link: '/about' },
  { text: 'Features', link: '/#pricing' },
  { text: 'Sign In', link: '/signin' },
]

const NavMenu = ({
  moveToStart,
  onClose,
  logout,
  clearGlobal,
}) => {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = (url) => () => {
    onClose()
    if(url=='/signin' && authInfo().tokens){
      handleLogout()
    }else{
      history.push(url)
    }
  }
  const handleLogout = async () => {
    if (authInfo().tokens) {
      const refreshToken = authInfo().tokens.refresh.token
      await logout({
        body: { refreshToken: refreshToken },
        success: async () => {
          await clearGlobal()
        }
      })
    }
    authClear()
    history.push('/')
    onClose()
  }

  const onClick = (e) => {
    onClose()
    if(e.path=='/signin' && authInfo().tokens){
      handleLogout()
    }else if(e.path=='/app/mysheet'){
      history.push('/app/mysheet/'+ mongoObjectId())
    }
    else{
      history.push(e.path)
    }
  }

  const handleMoveToStart = () => {
    history.push('/start')
  }

  return (
    <Container>
      <img className={classes.logo} onClick={handleClick('/')} src={white_sheet_logo} alt='logo' />
      <List component='nav' className={classes.linkList}>
        {
          content.map((item, key) => {
            if (item.link === '/signin' && authInfo().tokens) { return null }
            return (
              <ListItem button key={key} component='a' className={classes.linkItem} >
                <div
                  className={classes.linkText}
                  onClick={handleClick(item.link)}>
                  {item.text}
                </div>
              </ListItem>
            )
          })
        }
      </List>
      {/* {
        !authInfo().tokens &&
        <Button className={classes.home_btn}
        onClick={()=>handleMoveToStart()}
        >{"7-DAY FREE TRIAL"}&nbsp;<HiOutlineArrowRight /></Button>
      } */}
      {
        authInfo().tokens &&
        <div>
          <div className={cx(classes.navitem,'pb-20','pt-5')}>My Account</div>
          {authInfo().user.level=="1"?NavListAdmin.map((item) => {
            return <Button onClick={() => onClick(item)}
              className={cx(classes.navitem, classes.navItem_btn, item.path == "/signin" && classes.navItem_out,
                "props.path" == item.path ? classes.navitem_active : "")}>
              <Icon type={item.icon}
                className={cx(classes.navitem,
                  "props.path" == item.path ? classes.navitem_active : "")} />
              <div className='pl-10'>{item.label}</div></Button>
          }):NavList.map((item) => {
            return <Button onClick={() => onClick(item)}
              className={cx(classes.navitem, classes.navItem_btn, item.path == "/signin" && classes.navItem_out,
                "props.path" == item.path ? classes.navitem_active : "")}>
              <Icon type={item.icon}
                className={cx(classes.navitem,
                  "props.path" == item.path ? classes.navitem_active : "")} />
              <div className='pl-10'>{item.label}</div></Button>
          }
          )}

        </div>
      }
    </Container>
  )
}

NavMenu.propTypes = {
  onClose: PropTypes.func,
  setSuccessOpen: PropTypes.func,
  logout: PropTypes.func,
  clearGlobal: PropTypes.func,
}

const actions = {
  setSuccessOpen,
  logout,
  clearGlobal,
}

export default compose(connect(null, actions))(NavMenu)
