import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'
import tick from 'assets/lottie/Cricle_Tik.json'
import { LottiePlay } from 'components/LottiePlay'
import text_logo from 'assets/images/images/text_logo.png'

const detail = [
  {
    title: 'Step 1 - Register & Signin',
    description: `It is possible to register using email easily`
  },
  {
    title: 'Step 2 - Open Game Trax Sheet',
    description: `Once Game Trax is open, input the necessary point 
    and content according to the match`
  },
  {
    title: 'Step 3 - Save and Download',
    description: `Inputed data can be saved on the database 
    and can be downloaded to upload on HUDL`
  }
]

const status = [true, true, true, true]
let timerId = null
let lottieIndex = 0

const DetailGameSheet = () => {
  const classes = useStyles()
  const [playStatus, setPlayStatus] = useState(status)
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
    return () => {
      timerId && clearInterval(timerId)
      timerId = null
      lottieIndex = 0

      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    document.getElementById('detail-description').addEventListener('transitionend', () => {
      if (!timerId) { timerId = setInterval(lottieTime, 2000) }
    })
    const lottieTime = () => {
      lottieIndex > 3 && clearInterval(timerId)
      const tmpStatus = status.slice()
      tmpStatus[lottieIndex] = false
      setPlayStatus(tmpStatus)
      lottieIndex++
    }
  })

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        <img className={classes.logoImg} src={text_logo} alt='' />
        {/* Intuitive & Easy to Use */}
      </h1>
      <div>
        <h3>
          <p>
            &nbsp;We have developed the fastest and most accurate data system in football,
             completely automating the breakdown process. Say goodbye to handwritten hit 
             charts and transparencies. We deliver game and practice data in real-time, 
             allowing coaches to instantly access the tendency reports and hit charts 
             they need to confidently plan and strategize against their opponents.
          </p>
          <p>
            &nbsp;Our system is tailored to operate in each team’s specific language,
             ensuring accuracy from the start and eliminating the need for translation.
          </p>
          <p>
            &nbsp;By using our system, coaches can save 20-25 hours a week.
             This valuable time can be redirected towards perfecting game plans,
              devising unique strategies to outmaneuver opponents, or enjoying more
               quality time with family.
          </p>
        </h3>
      </div>
    </div>
  )
}

export default DetailGameSheet
