import { createAction } from 'redux-actions'
import * as CONSTANTS from './constants'

export const setSearchMode = createAction(CONSTANTS.SET_SEARCH_MODE)
export const setSubscribeOpen = createAction(CONSTANTS.SET_SUBSCRIBE_OPEN)
export const setSuccessOpen = createAction(CONSTANTS.SET_SUCCESS_OPEN)
export const setRequestVerifyOpen = createAction(CONSTANTS.SET_REQUEST_VERIFY_OPEN)
export const setIsSubscribeLoading = createAction(CONSTANTS.SET_IS_SUBSCRIBE_LOADING)
export const setCookie = createAction(CONSTANTS.SET_COOKIE)
export const clearGlobal = createAction(CONSTANTS.CLEAR_GLOBAL)
export const closeVerification = createAction(CONSTANTS.CLOSE_VERIFICATION)
export const setResetPasswordToken = createAction(CONSTANTS.SET_RESET_PASSWORD_TOKEN)
