import React from 'react'
import useStyles from './Form.styles'
import {
  Container,
} from '@material-ui/core'
import { CustomButton } from 'components/CustomButton'
import successImg from 'assets/images/logo/white_logo.svg'
import { useHistory } from 'react-router-dom'
import * as cx from 'classnames'

const Form = ({ onClose, customInfo }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleBrowse = () => {
    // history.push('/search?mode=Icons')
    onClose()
  }

  return (
    <>
      <div className={classes.root}>
        <Container>
          <div className='center-center'>
            <img src={customInfo ? successImg : successImg}
              alt='success' className={classes.successImg} /></div>
          <div className={classes.title}>
            {'Welcome to Game Trax!'}
          </div>
          <div className={cx('center-column',classes.description)}>
            <div className='center-center pb-10' style={{ textAlign: 'center' }}>
              The email has been sent to create your new password.<br />
              Please check your email box.
            </div>
            <div className='pb-40' style={{ textAlign: 'center' }}>
              {
                customInfo && customInfo.description != undefined ? customInfo.description :
                  <div><span>Your email</span><span 
                  className={classes.descriptionColor}> {customInfo?customInfo.email:"Game Trax"}</span></div>}
            </div>
            <div className='pb-30'></div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Form
