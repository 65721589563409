import React, { useState, useEffect } from 'react'
import { Provider } from 'react-redux'
import store from 'redux/store'
import Routes from 'routes'
import { BrowserRouter } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { GMTID } from 'helpers/utils'

const App = () => {

  useEffect(() => {
    TagManager.initialize({ gtmId: GMTID })
    }, [])

  return (
    <BrowserRouter>
    <Provider store={store}>
      <Routes />
    </Provider>
  </BrowserRouter>
  )
}

export default App
