import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
    title0:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '48px',
        lineHeight: '72px',
        /* identical to box height */
        color: 'white',
        textAlign:'center'
    },
    title1:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '24px',
        lineHeight: '36px',
        /* identical to box height */
        color: '#333333',
    },
    title2:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '32px',
        /* identical to box height */
        color: 'white',
        fontWeight:600
    },
    desc1:{
        fontFamily: 'Gotham',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        /* identical to box height */
        color: 'white',
    },
    desc2:{
        fontStyle: 'normal',
        fontSize: '14px!important',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        paddingBottom: '50px!important'
    }
}))