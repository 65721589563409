// src/WordSlider.js
import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container } from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'

const FeatureSlider = () => {
    const isMobile = useMediaQuery({ maxWidth: 600 })
    const classes = useStyles()
    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: isMobile?2:5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        vertical: false,
        arrows: true,
    }

    const words = ["LIVE STATS", "TOP PLAYS", "OFFENSIVE PLAYS", "DEFENSE", "SELF SCOUT", "TENDENCIES","PERSONNEL","FORMATIONS"]

    return (
        <Container className={classes.root}>
            <div className="word-slider">
                <Slider {...settings}>
                    {words.map((word, index) => (
                        <div key={index} style={{padding:'20px'}}>
                            <h3>{word}</h3>
                        </div>
                    ))}
                </Slider>
            </div>
        </Container>
    )
}

export default FeatureSlider
