import React, { useEffect, useState, useRef } from 'react'
import useStyles from './styles'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'

const status = [true, true, true, true]
let timerId = null
let lottieIndex = 0

const contentList=[
    {
        "title":"Real-time game analysis",
        "description":"Our live stat tracking feature provides real-time game analysis,"
        +"giving you up-to-the-minute data to enhance your understanding of the game as "
        +"it unfolds. This allows for more informed decision-making and strategic "
        +"adjustments during play."
    },
    {
        "title":"Seamlessly compatible with HUDL",
        "description":"Our system is seamlessly compatible with HUDL, ensuring easy"
        +" data upload and keeping your information synchronized and accessible."
        +" This integration allows you to maintain a smooth workflow, ensuring"
        +" your data is always up-to-date."
    },
    {
        "title":"Identify Best Metrics",
        "description":"Our platform offers insightful analytics to help you identify"
        +" your most effective plays and successful formations. This data-driven"
        +" approach gives you a strategic edge, allowing you to optimize performance"
        +" and make more informed decisions."
    },
    {
        "title":"Eliminates redundant data entry",
        "description":"The time-saving functionality of our system eliminates redundant data"
        +" entry, streamlining your workflow. This efficiency allows you to focus more on "
        +"game strategy and performance rather than on administrative tasks."
    }
]

const ContentTwo = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const isMobile = useMediaQuery({ maxWidth: 600 })
    const [playStatus, setPlayStatus] = useState(status)
    const isMounted = useRef(null)

    useEffect(() => {
        isMounted.current = true
        return () => {
            timerId && clearInterval(timerId)
            timerId = null
            lottieIndex = 0

            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        document.getElementById('detail-description').addEventListener('transitionend', () => {
            if (!timerId) { timerId = setInterval(lottieTime, 2000) }
        })
        const lottieTime = () => {
            lottieIndex > 3 && clearInterval(timerId)
            const tmpStatus = status.slice()
            tmpStatus[lottieIndex] = false
            setPlayStatus(tmpStatus)
            lottieIndex++
        }
    })

    console.log("props data===:",props.index)

    return (
        <div>
            {!isMobile && <h1 className={classes.title}>
                {contentList[props.index]['title']}
            </h1>}
            <div>
                <h3>
                    {contentList[props.index]['description']}
                </h3>
            </div>
            {/* <Button className={classes.home_btn} onClick={()=>handleMoveToStart()}>
        {"7-DAY FREE TRIAL"}&nbsp;<HiOutlineArrowRight /></Button> */}
        </div>
    )
}

export default ContentTwo
