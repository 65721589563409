import React from 'react'
import { Mobile, Default } from 'containers/ResponseLayout'
import Form from './Form'

const SignUpForm = ({ onClose }) => {

  return (
    <>
      <Mobile>
        <Form />
      </Mobile>

      <Default>
          <Form />
      </Default>
    </>
  )
}

export default SignUpForm
