import React, { useState, useMemo, useEffect } from 'react'
import {
    Container,
    Grid,
    Button,
    IconButton,
    LinearProgress,
} from '@material-ui/core'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import useStyles from './styles'
import { Mobile, Default } from 'containers/ResponseLayout'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import paymentAsset from 'assets/images/images/payment.png'
import paymentCards from 'assets/images/images/payment_cards.svg'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'

import OrderCard from './orderCard'
import TwoMark from './towMark'
import {
    setSuccessOpen,
  } from 'redux/modules/global/actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { signup, checkEmailValidation } from 'redux/modules/auth/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { roles } from 'helpers/AppConfig'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"

import useResponsiveFontSize from "./useResponsiveFontSize"
import { CustomAlert } from 'components/CustomAlert'

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 1)',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        width: '350px'
    },
}))

const useOptions = () => {
    const fontSize = useResponsiveFontSize()
    const optionsNumber = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "Credit Card Number"
        }),
        [fontSize]
    )

    const optionsExp = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "Exp (MM/YY)"
        }),
        [fontSize]
    )

    const optionsCvv = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "inherit",
                    "::placeholder": {
                        color: "#7A7D85"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            },
            placeholder: "CVV"
        }),
        [fontSize]
    )

    return [optionsNumber, optionsExp, optionsCvv]
}

const RegisterStart = (props) => {

    const [formInput, setFormInput] = useState({})
    const [email, setEmail] = useState(null)
    const [editPayment, setEditPayment] = useState(false)
    const [postCode, setPostCode] = useState("")
    const [name, setName] = useState("")
    const [open, setOpen] = React.useState(false)
    const [showError, setShowError] = useState({
        status: false,
        text: ""
    })
    const [errorState, setErrorState] = useState({
        name: "",
        zipcode: ""
    })
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertText, setAlertText] = useState('')
    const [isYear, setIsYear] = useState(false)
    const classes = useStyles()
    const stripe = useStripe()
    const elements = useElements()
    const options = useOptions()
    const history = useHistory()

    const { 
        setSuccessOpen,
        signup,
        setIsSubscribeLoading
    } = props

    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const isYear = params.get('mode')
        if (isYear == "year") {
            setIsYear(true)
        }
        // setSuccessOpen({ open: true,
        //     customInfo: {
        //         email: "email@test.com"
        //         // img: subscriptionImg,
        //         // title: 'You are now part of the MyGameTrax family!',
        //         // description: `Congratulations, you made the right decision!
        //         //  You now have unlimited access to our ${info.text} library.`
        //       } })
    }, [])

    const handleInput = evt => {
        const name = evt.target.name
        const newValue = evt.target.value
        setFormInput({ [name]: newValue })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setEmail(e.target.email.value)
        props.setIsSubscribeLoading({ isLoading: true })
        props.checkEmailValidation({
            body: { email: e.target.email.value },
            success: (() => {
                props.setIsSubscribeLoading({ isLoading: false })
                setEditPayment(true)
            }),
            fail: (err) => {
                props.setIsSubscribeLoading({ isLoading: false })
                const error = err.data.message
                setAlertText(error)
                setAlertOpen(true)
            }
        })
    }

    const inputCode = (e) => {
        console.log("input code --0:", e.target.value)
        if (e.target.value == "") {
            setErrorState({
                ...errorState,
                zipcode: ""
            })
        } else {
            if (parseInt(e.target.value) > 0) {
                setErrorState({
                    ...errorState,
                    zipcode: ""
                })
                setPostCode(e.target.value)
            } else {
                setErrorState({
                    ...errorState,
                    zipcode: "Please Input Number"
                })
            }
        }
    }

    const inputName = (e) => {
        if (e.target.value == "") {
            setErrorState({
                ...errorState,
                name: ""
            })
        } else {
            setErrorState({
                ...errorState,
                name: ""
            })
            setName(e.target.value)
        }
    }

    const paymentSubmit = async (event) => {
        event.preventDefault()
        if (!(name.length > 0)) {
            setErrorState({
                ...errorState,
                name: "Please Input Full Name"
            })
            // } else if (!(postCode.length > 0)) {
            //     setErrorState({
            //         ...errorState,
            //         zipcode: "Please Input Postcode"
            //     })
        }
        else if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
        } else {
            const payload = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement)
            })
            if (payload.error != undefined) {
                setAlertText(payload.error.message)
                setAlertOpen(true)
            } else {
                if (postCode.length > 0) {
                    var paymentMethodId = payload.paymentMethod.id
                    var last4 = payload.paymentMethod.card.last4
                    var licensePackageId = 1
                    var role = roles[1]
                    if (isYear) {
                        licensePackageId = 2
                        role = roles[2]
                    }
                    console.log("submit ==00:", paymentMethodId)
                    props.setIsSubscribeLoading({ isLoading: true })
                    signup({
                        body: { email, name, paymentMethodId, postCode, licensePackageId, last4, role },
                        success: ({ data }) => {
                            setIsSubscribeLoading({ isLoading: false })
                            history.push('/signin')
                            setSuccessOpen({
                                open: true,
                                customInfo: {
                                    email: email
                                    // img: subscriptionImg,
                                    // title: 'You are now part of the MyGameTrax family!',
                                    // description: `Congratulations, you made the right decision!
                                    //  You now have unlimited access to our ${info.text} library.`
                                }
                            })
                        },
                        fail: (err) => {
                            setIsSubscribeLoading({ isLoading: false })
                            const error = err.data.message
                            setAlertText(error.replace(`"email"`, 'Email'))
                            setAlertOpen(true)
                        }
                    })
                    console.log("please send the code to backend")
                } else {
                    setErrorState({
                        ...errorState,
                        zipcode: "Please Input Postcode"
                    })
                }
            }
        }
    }

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        setOpen(true)
    }

    return (
        <Container className={classes.root}>
            <div className={cx(classes.title, 'center-center', 'pb-10')}>Start your free trial</div>

            {props.isSubscribeLoading ? <div className='linear-height'>
                <LinearProgress />
            </div> : <div className='linear-height-border'>
            </div>}
            <Grid container spacing={2} className='pt-10'>
                <Mobile>
                    {
                        !editPayment ? <div className={cx(classes.cardEmail, 'mt-10')}>
                            <div className={cx(classes.titleInCard, 'pb-10', 'center-start')}>
                                <div>1. Enter your email address:</div>
                            </div>
                            <form onSubmit={handleSubmit} className='payment-input'>
                                <div className='pb-5'><input className={classes.inputField}
                                    onChange={handleInput} type='email' name='email' defaultValue={email}
                                    placeholder='Your email address' required /></div>
                                <div className='pb-10'>We'll email your purchase receipt
                                    to this email address.</div>
                                <div className='center-end'>
                                    <Button className={cx(props.isSubscribeLoading == false ? ""
                                        : classes.home_btn_disable, classes.home_btn)} type="submit">
                                        {"Next"}&nbsp;<HiOutlineArrowRight /></Button>
                                </div>
                            </form>
                        </div> : <div className={cx(classes.cardEmail, 'mt-10')}>
                            <div className={cx(classes.titleInCard, 'pb-10', 'center-between')}>
                                <div>1. Enter your email address:</div>
                                <IconButton onClick={() => {
                                    setEditPayment(false)
                                }}><div className={classes.editBtn}>Edit</div></IconButton>
                            </div>
                            <div className={classes.emailText}>{email}</div>
                        </div>
                    }
                    <div className='pb-20'></div>
                    <OrderCard isYear={isYear} />
                    <Grid xs={12}>
                        <div className={cx(classes.cardSummary, 'mt-20', 'payment-input')}>
                            <div className={cx(classes.titleInCard, 'pb-10', 'center-start')}>
                                <div>2. Enter payment information</div>
                            </div>
                            <div className='center-start pb-10'>
                                <img src={paymentCards} alt="" />
                            </div>
                            {props.isSubscribeLoading ? <div className='linear-height mb-10'>
                                <LinearProgress />
                            </div> : <div className='linear-height-border mb-10'>
                            </div>}
                            <form onSubmit={paymentSubmit} className='pb-20'>
                                <div className=''><input className={classes.inputField}
                                    placeholder='Please enter name' type='text'
                                    name="name" onChange={inputName} /></div>
                                <div className={classes.errorText}>{
                                    errorState.name != "" ? errorState.name : ""}</div>
                                <div className='pb-10 pt-10'>
                                    <CardNumberElement
                                        options={options[0]}
                                        onReady={() => {
                                            console.log("CardNumberElement [ready]")
                                        }}
                                        onChange={event => {
                                            console.log("CardNumberElement [change]", event)
                                        }}
                                        onBlur={() => {
                                            console.log("CardNumberElement [blur]")
                                        }}
                                        onFocus={() => {
                                            console.log("CardNumberElement [focus]")
                                        }}
                                    />
                                </div>
                                <Grid container spacing={2} className='pb-10'>
                                    <Grid item xs={12} md={6} className={classes.mpadding}>
                                        <CardExpiryElement
                                            options={options[1]}
                                            onReady={() => {
                                                console.log("CardNumberElement [ready]")
                                            }}
                                            onChange={event => {
                                                console.log("CardNumberElement [change]", event)
                                            }}
                                            onBlur={() => {
                                                console.log("CardNumberElement [blur]")
                                            }}
                                            onFocus={() => {
                                                console.log("CardNumberElement [focus]")
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className='position-relative'>
                                            <CardCvcElement
                                                options={options[2]}
                                                onReady={() => {
                                                    console.log("CardNumberElement [ready]")
                                                }}
                                                onChange={event => {
                                                    console.log("CardNumberElement [change]", event)
                                                }}
                                                onBlur={() => {
                                                    console.log("CardNumberElement [blur]")
                                                }}
                                                onFocus={() => {
                                                    console.log("CardNumberElement [focus]")
                                                }}
                                            />
                                            <div className="posAlert">
                                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                                    <div>
                                                        <LightTooltip
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={handleTooltipClose}
                                                            open={open}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title="CVV info text: This is the last 3 digits
                                                 printed on the back of your credit card or the 4-digit number 
                                                 printed on the front of your American Express.">
                                                            <Button onClick={handleTooltipOpen}><IoMdAlert size={20} />
                                                            </Button>
                                                        </LightTooltip>
                                                    </div></ClickAwayListener>

                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div className='pb-10'><input className={classes.inputField}
                                    placeholder='Zip/Postal code' type='text' inputMode='numeric' maxLength={6}
                                    name="zipCode" onChange={inputCode} /></div>
                                <div className={classes.errorText}>{
                                    errorState.zipcode != "" ? errorState.zipcode : ""}</div>
                                <Grid className='pb-10'>
                                    <div className="center-start">
                                        <img src={regLock} alt="lock" />
                                        <div className={classes.poweredby}>&nbsp;Powered by&nbsp;</div>
                                        <img src={regStripe} alt="stripe" />
                                    </div>
                                </Grid>
                                <Grid className='pb-10'>
                                    <Button className={classes.home_btn} type="submit">
                                        {"Join Now"}&nbsp;<HiOutlineArrowRight /></Button>
                                </Grid>
                                <div className='center-between pt-10 pb-10'>
                                </div>
                            </form>
                            <div className={cx('pt-5', classes.paymentDesc)}>
                                By submitting this form, your agree to our&nbsp;
                                <a className={classes.termPolicy} onClick={() => history.push("/terms")}>
                                    Terms of Service</a>
                                <span> and </span>
                                <a className={classes.termPolicy} onClick={() => history.push("/policy")}>
                                    Privacy Policy</a></div>
                            <div className={editPayment == true ? "" : 'input-cover'}></div>
                            <CustomAlert isOpen={alertOpen} type='error' text={alertText}
                                onClose={() => setAlertOpen(false)} />
                        </div>
                    </Grid>
                    {/* <Grid xs={12}>
                        <TwoMark />
                    </Grid> */}
                </Mobile>
                <Default>
                    <Grid item xs={6}>
                        <div>
                            {
                                !editPayment ? <div className={classes.cardEmail}>
                                    <div className={cx(classes.titleInCard, 'pb-10', 'center-start')}>
                                        <div>1. Enter your email address:</div>
                                    </div>
                                    <form onSubmit={handleSubmit} className='payment-input'>
                                        <div className='pb-5'><input className={classes.inputField}
                                            onChange={handleInput} type='email' name='email' defaultValue={email}
                                            placeholder='Your email address' required /></div>
                                        <div className='pb-10'>We'll email your purchase receipt
                                            to this email address.</div>
                                        <div className='center-end'>
                                            <Button className={cx(props.isSubscribeLoading == false ? ""
                                                : classes.home_btn_disable, classes.home_btn)} type="submit">
                                                {"Next"}&nbsp;<HiOutlineArrowRight /></Button>
                                        </div>
                                    </form>
                                </div> : <div className={classes.cardEmail}>
                                    <div className={cx(classes.titleInCard, 'pb-10', 'center-between')}>
                                        <div>1. Enter your email address:</div>
                                        <IconButton onClick={() => {
                                            setEditPayment(false)
                                        }}><div className={classes.editBtn}>Edit</div></IconButton>
                                    </div>
                                    <div className={classes.emailText}>{email}</div>
                                </div>
                            }
                            <div className={cx(classes.cardSummary, 'mt-20', 'payment-input')}>
                                <div className={cx(classes.titleInCard, 'pb-10', 'center-start')}>
                                    <div>2. Enter payment information</div>
                                </div>
                                <div className='center-start pb-20'>
                                    <img src={paymentAsset} alt="" />
                                </div>
                                <hr className={classes.lineTwo}></hr>
                                <div><input className={classes.inputField}
                                    placeholder='Full Name' type='text' inputMode='numeric'
                                    name="name" onChange={inputName} /></div>
                                <div className={classes.errorText}>{
                                    errorState.name != "" ? errorState.name : ""}</div>
                                <form onSubmit={paymentSubmit} className='pb-30'>
                                    <div className='pb-10 pt-10'>
                                        <CardNumberElement
                                            options={options[0]}
                                            onReady={() => {
                                                console.log("CardNumberElement [ready]")
                                            }}
                                            onChange={event => {
                                                console.log("CardNumberElement [change]", event)
                                            }}
                                            onBlur={() => {
                                                console.log("CardNumberElement [blur]")
                                            }}
                                            onFocus={() => {
                                                console.log("CardNumberElement [focus]")
                                            }}
                                        />
                                    </div>
                                    <Grid container spacing={2} className='pb-10'>
                                        <Grid item xs={6}>
                                            <CardExpiryElement
                                                options={options[1]}
                                                onReady={() => {
                                                    console.log("CardNumberElement [ready]")
                                                }}
                                                onChange={event => {
                                                    console.log("CardNumberElement [change]", event)
                                                }}
                                                onBlur={() => {
                                                    console.log("CardNumberElement [blur]")
                                                }}
                                                onFocus={() => {
                                                    console.log("CardNumberElement [focus]")
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className='position-relative'>
                                                <CardCvcElement
                                                    options={options[2]}
                                                    onReady={() => {
                                                        console.log("CardNumberElement [ready]")
                                                    }}
                                                    onChange={event => {
                                                        console.log("CardNumberElement [change]", event)
                                                    }}
                                                    onBlur={() => {
                                                        console.log("CardNumberElement [blur]")
                                                    }}
                                                    onFocus={() => {
                                                        console.log("CardNumberElement [focus]")
                                                    }}
                                                />
                                                <div className="posAlert">
                                                    <LightTooltip title="CVV info text: This is the last 3 digits
                                                printed on the back of your credit card or the 4-digit number 
                                                printed on the front of your American Express.">
                                                        <Button><IoMdAlert size={20} /></Button>
                                                    </LightTooltip>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className='pb-10'><input className={classes.inputField}
                                        placeholder='Zip/Postal code' type='text' inputMode='numeric' maxLength={6}
                                        name="zipCode" onChange={inputCode} /></div>
                                    <div className={classes.errorText}>{
                                        errorState.zipcode != "" ? errorState.zipcode : ""}</div>
                                    <div className='center-between pt-10 pb-10'>
                                        <div className="center-start">
                                            <img src={regLock} alt="lock" />
                                            <div className={classes.poweredby}>&nbsp;Powered by&nbsp;</div>
                                            <img src={regStripe} alt="stripe" />
                                        </div>
                                        <Button className={cx(editPayment == true ? ""
                                            : classes.home_btn_disable, !props.isSubscribeLoading ? ""
                                            : classes.home_btn_disable, classes.home_btn)} type="submit">
                                            {"Join Now"}&nbsp;<HiOutlineArrowRight /></Button>
                                    </div>
                                </form>
                                <div className={cx('pt-5', classes.paymentDesc)}>
                                    By submitting this form, your agree to our&nbsp;
                                    <a className={classes.termPolicy} onClick={() => history.push("/terms")}>
                                        Terms of Service</a>
                                    <span> and </span>
                                    <a className={classes.termPolicy} onClick={() => history.push("/policy")}>
                                        Privacy Policy</a></div>
                                <div className={editPayment == true ? "" : 'input-cover'}></div>
                                <CustomAlert isOpen={alertOpen} type='error' text={alertText}
                                    onClose={() => setAlertOpen(false)} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <OrderCard isYear={isYear} />
                    </Grid>
                </Default>
                <div className={props.isSubscribeLoading ? 'input-cover' : ""}></div>
            </Grid>
        </Container>
    )
}

RegisterStart.propTypes = {
    setSuccessOpen: PropTypes.func,
    signup: PropTypes.func,
    checkEmailValidation: PropTypes.func,
    setIsSubscribeLoading: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
}

const actions = {
    setIsSubscribeLoading,
    checkEmailValidation,
    setSuccessOpen,
    signup,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
})

export default compose(connect(selector, actions))(RegisterStart)
