import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    width: '520px',
    background: '#FFFFFF',
    borderRadius: '15px',
    padding: '30px 37px',
    marginTop: '60px',
    height: 'calc(100vh-500px)',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
      borderRadius: '5px',
      marginTop: '20px',
      padding: '5px 10px 5px 20px',
    }
  },
  title: {
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    '@media only screen and (max-width: 600px)': {
      fontSize: '26px',
      lineHeight: '30px',
      textAlign: 'left',
    }
  },
  description: {
    color: '#118A2D',
    background: '#D4EDDA',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    padding: '17px',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '21px',
    borderRadius: '5px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
    }
  },
  divider: {
    marginBottom: '24px !important',
  },
  divider2: {
    marginTop: '12px !important',
    marginBottom: '12px !important',
  },
  signButton: {
    height: theme.spacing(5),
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  label1: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    marginBottom: theme.spacing(0.5),
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },
  label2: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '12px',
      lineHeight: '14px',
    }
  },
  label3: {
    color: '#333333',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
    '& > span': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#0052FF'
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '16px',
    }
  },
  wrapper: {
    position: 'relative',
  },
  home_btn: {
    width: '100%',
    height: '50px',
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '7px',
    paddingBottom: '5px',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },

  buttonProgress: {
    color: 'white'
  },

  posAlert: {
    position: 'absolute',
    right: '9px',
    top: '16px'
  },

  resendText: {
    textAlign:'center',
    color: '#333333'
  },
  resendLink:{
    textDecoration:'underline',
    color: '#333333'
  }
}))
