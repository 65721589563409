// const STRIPE_PUBLIC_KEY = `pk_test_51MMuopDr5wWFk0xqoXihfu4tzEZHeKuPearn7AhfXkF48i0LAsbp
//lfmy9jdK0NqRKuZEClTjqEjvD9bWHL1xyLMW00fujzHMh4` // test key

const STRIPE_PUBLIC_KEY = `pk_live_51MMuopDr5wWFk0xqFbRwWTKZCMD3zN6mr6nZzq3VpR9EV3DmMw3y9jc4J
PxRY9cGBODPuJwfz5iJOpMX3h4avlHy008oPccoOi`

const GMTID = `G-XJJ1M92KYT`

const PAYPAL_CLIENT_KEY = `AZ19bnUjXKRdzU-wqC-VPo9hE7_NPH6C6LSMFpkWVD1ElRAPE3rGhPe3YRUFatDG4Odilx1YROMs3me7`

const SERVER_BASE_URL = "https://sheet.mygametrax.com/api/v1/"
// const SERVER_BASE_URL = 'http://localhost:5000/api/v1/'


const LIVE_DATA_KEYS = [
  "id", 
  "OPP TEAM",
  "QTR", "P DOWN", ///New
  "PLAY #", "ODK", "DN", "DIST", "Yard LN", "HASH", "GN/LS",
  "PLAY TYPE", "RESULT", "DEF FRONT", "STUNT", "BLITZ",
  "PERSONNEL", ///New
  "COVERAGE",
  "OFF PLAY TREE", "OFF FORM TREE", "GAP", ///New
  "OFF FORM", "MOTION", "PASS PRO", "OFF PLAY", "RUSHER", "PASSER", "RECEIVER"
]

const COLUMN_WITH_SELECTION_ORIGIN = {
  "OPP TEAM": ["GARLAND", "OPPONENT", "WEATHERFORD", "JESUIT", "BERKNER", "NIMITZ", "MACARTHUR",
    "RICHARDSON", "LAKE HIGHLANDS", "IRVING", "HIGHLAND PARK"],

  "HASH": ["L", "M", "R"],

  "PLAY TYPE": ["EXTRA PT", "EXTRA PT BLOCK", "FG", "FG BLOCK", "KO", "KOR", "PASS", "RUN", "PUNT", "PUNT RET", "BAD SNAP"],

  "RESULTS": ["COMPLETE", "COMPLETE,TD", "RUSH", "RUSH,TD", "INCOMPLETE", "INCOMPLETE,TO", "COMPLETE,TO",
    "RUSH,TO", "FUMBLE", "INTERCEPTION", "PENALTY", "DOWNED", "GOOD", "NO GOOD", "RETURN", "FAIR CATCH", "SACK",
    "SAFETY", "FUMBLE,TD", "INTERCEPTION,TD", "SCRAMBLE", "SACK,TO", "QUARTER", "HALFTIME",
    "OUT OF BOUNDS", "BLOCK", "TIMEOUT", "PUNT", "TOUCHBACK", "SCRAMBLE,TD", "RETURN, TD", "FUMBLE, DEF TD"],

  "DEF FRONT": ["TITE", "BOULDER", "BANDIT", "FOX", "TITE MUG", "BACK", "AUTO",
    "WEATHER CHECK", "MOUSE", "BOUNDARY", "BOX", "FIGHT", "SET"],

  "STUNT": ["SWITCH", "RAM", "AXE", "GATE", "WRAP", "CROSS", "TWIST", "LIGHTNING"],

  "COVERAGE": ["4", "2", "DOUBLE ROBBER", "INVERT", "5", "ROL", "4 BUZZ", "4 SKY", "4 ROBBER"],

  "OFF PLAY TREE": ["POWER RT", "POWER LT", "COUNTER RT", "COUNTER LT", "IZONE RT",
    "IZONE LT", "OZONE RT", "OZONE LT", "DRAW"],

  "OFF FORM TREE":["2X2","3X1"],

  "GAP":["A","B","C","D"],

  "OFF FORM": ["ACE", "ACE EARLY SNIFFER", "ACE FLIP", "ACE FLIP SNIFFER",
    "ACE SNIFFER", "EARLY", "EARLY SNIFFER", "EARLY SNIFFER FLIP", "SNIFFER EARLY",
    "ACE ACE FLIP", "ACE YO", "EARLY YO", "ACE FLIP YO", "FLORIDA", "BLUE FLIP", "HAMMER",
    "HEAVY", "BLUE", "HEAVY OVER", "NAIL", "BEAST", "BEAST OVER", "LAKE", "GLASS", "JUMBO",
    "TITAN", "TRIPS", "RIVER", "GREASE", "HEAVY FLIP", "CARDINAL LEFT", "CARDINAL RIGHT",],

  "MOTION": ["Z POP", "H MOVE", "Z MOVE", "H POP"],

  "PASS PRO": ["90", "91", "60", "61", "80", "81", "LASSO", "RODEO", "TEXAS", "RHINO",
    "ELEPHANT", "MISSILE R", "MISSILE L", "80 DOWN"],

  "OFF PLAY": ["ACE Z POP", "GY R", "POWER R", "Y BUBBLE", "CHOICE", "H POP", "SAIL",
    "Y CROSS", "COP L", "HI LO", "SAIL X POST", "ZONE L", "COP R", "HITCH & GO", "SHAKE", "ZONE R",
    "CURL OUT", "HITCHES", "SMASH", "CURLS", "MARS", "SNAP", "CURLS X POST", "POW L X FAST", "STR L",
    "F SWING L", "POW OPT L", "STR R", "GO'S", "POW OPT R", "THINK", "GY L", "POW R", "X FAST",
    "X JAIL", "STRETCH LEFT", "FREEZE", "STRETCH COMEBACK", "KICK L", "H SHALLOW", "SQUIRREL",
    "KICK R", "H FAST", "STRETCH L KEEP", "FLORIDA", "F CORNER", "STRETCH R KEEP", "Y SHALLOW",
    "STREETS COMEBACK Z", "STRETCH OPTION R", "DAGGER", "FLAME CURL OUT", "CURLS Z POST",
    "RANGLER", "STRETCH OPTION L", "SCOTTS", "SNAP AND GO", "Z JAIL", "FIRE SLUGGO R",
    "NAIL", "Z SHALLOW", "X FOX", "FLAME COP L", "RENO", "OUT AND UP", "H CROSS",
    "FIRE COP R", "FLAME RUB", "Z GO", "SOLIDER RIGHT", "CURL OUT KICK RIGHT",
    "POWER OPTION LEFT", "HAMMER", "DRAWY", "DRAW THINK", "H MOVE GY LEFT",
    "FIRE COP RIGHT", "GT LEFT", "GT RIGHT", "Y SHALLOW RETURN",
    "H MOVE GY RIGHT READ", "OUT", "HIGH LOW", "BLADE", "POWER RIGHT",
    "STRETCH RIGHT", "TOSS RIGHT", "Z POP STRETCH LEFT", "TOSS RIGHT PASS",
    "POWER LEFT H COWBOY", "HITCHES H GO", "POWER LEFT", "ZONE RIGHT READ",
    "F SLIP", "KICK LEFT SLANT", "SLUGGO RIGHT", "Y SQUEEZE RUB", "Z POP PASS",
    "H JAIL DICE", "RUB", "WRANGLER", "GY RIGHT READ", "SMASH F SEAM",
    "GY LEFT READ", "RIVER", "LAKE", "GLASS", "GREASE", "X GO", "Y THROWBACK",
    "FLOOD", "SHOVEL LEFT", "SHOVEL RIGHT", "POWER LEFT KEEP", "POWER RIGHT KEEP",
    "PICK", "SCISSORS", "H SLIP", "GY RIGHT KEEP", "GY LEFT KEEP", "ZONE RIGHT",
    "ZONE LEFT", "Y RENO", "POWER RIGHT STAY", "POWER LEFT STAY", "GY LEFT GLANCE",
    "GY RIGHT GLANCE", "GY L READ", "GY R READ", "GY LEFT", "GY RIGHT"]
}

const DROP_INPUT_KEYS = [
  "id", 
  "OPPONENT", "RESULTS", "DEF FRONT", "STUNT", "BLITZ", 
  "PERSONNEL", ///new
  "COVERAGE", 
  "OFF PLAY TREE", "OFF FORM TREE",
  // "GAP", ///new 
  "OFF FORM", "MOTION", "PASS PRO", "OFF PLAY"
]

const OFFENSIVE_PLAYS_KEYS = [
  "PLAY #", "OFF PLAY", "OFF FORM", "RESULT", "GN/LS"
]

const DEFENSE_KEYS = [
  "PLAY #", "DOWN", "DIST", "YN LN","DEF FRONT", "STUNT", "BLITZ", "COVERAGE",  "PLAY TYPE","OFF PLAY","GAP","OFF FORM","RESULT","GN/LS"
]

export {
  STRIPE_PUBLIC_KEY,
  PAYPAL_CLIENT_KEY,
  GMTID,
  SERVER_BASE_URL,
  LIVE_DATA_KEYS,
  COLUMN_WITH_SELECTION_ORIGIN,
  DROP_INPUT_KEYS,
  OFFENSIVE_PLAYS_KEYS,
  DEFENSE_KEYS
}