import React, { useState, useRef, memo } from 'react'
import ReactPlayer from 'react-player'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { useMediaQuery } from 'react-responsive'
import 'react-alice-carousel/lib/alice-carousel.css'
import { Button } from '@material-ui/core'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import priceTop from 'assets/images/images/price_top.png'
import { useHistory } from 'react-router'


const LookingForSection = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const handleMoveToStart = (value) => {
    history.push('/app/subscription')
    // props.moveToStart(value)
  }

  return (
    <Container className={classes.root}>
      <div className={classes.title}>Pricing</div>
      <Grid container spacing={3} className="center-center">
        <div className={classes.managerCard}>
          <div className={classes.managerPrice}>Billed Yearly at $750 (Locked Price)</div>
          <div className={classes.managerDetail}>
            Experience the full benefits of our platform with a paid subscription, including:<br />
            <ul>
              <li>
                <span>Create and Save Sheets: </span>
                Easily create sheets and save them to the database.
              </li>
              <li>
                <span>Live Stats:</span>
                Access real-time statistics based on your data.
              </li>
              <li>
                <span>Download Anytime:</span>
                Download your data at any time for further analysis or integration.
                The first-year price is locked in, meaning you'll pay the same rate forever. 
                Your subscription includes all updates and new features at no additional cost.
              </li>
            </ul>
            </div>
          <Button className={classes.home_btn}
           onClick={()=>handleMoveToStart()}
          >{"Get started"}&nbsp;<FaArrowRight /></Button>
        </div>
      </Grid>
    </Container>
  )
}

export default LookingForSection
