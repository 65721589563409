import React, { useState, useMemo, useEffect } from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import {
    Chip,
    Button,
    TablePagination,
    ButtonGroup,
    LinearProgress,
    IconButton
} from '@material-ui/core'
import { Space,Table, Tag, Popconfirm } from 'antd'
// import Table from "ant-responsive-table"
import useStyles from './styles'
import * as cx from 'classnames'
import { BsSearch } from 'react-icons/bs'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { refresh } from 'redux/modules/auth/actions'
import { getLiveDataByUser, deleteLiveData } from 'redux/modules/sheet/actions'
import { updateUser } from 'redux/modules/user/actions'
import moment from 'moment'

const Sheets = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [userList, setSheetList] = useState({})
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 100, { value: -1, label: 'All' }])
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        if (props.currentUser != null) {
            setIsLoading(true)
            props.getLiveDataByUser({
                params: {
                    user: props.currentUser.id,
                    docName: searchValue ? searchValue : '',
                    level: '2',
                    sortBy: 'asc',
                    limit,
                    page,
                },
                success: ({ data }) => {
                    console.log("data.results:", data)
                    setSheetList(data)
                    setRowsPerPageOptions([10, 25, 100, { value: data.totalDocs, label: 'All' }])
                    setIsLoading(false)
                },
                fail: (err) => {
                    setIsLoading(false)
                    //console.log(err)
                }
            })
        }
    }, [props.currentUser])

    useEffect(()=>{
        getSheets(page, limit)
    },[page,limit])

    const handleChangePage = (e, value) => {
        setPage(value + 1)
    }

    const handleChangeRowPerPage = (e) => {
        const value = e.target.value
        setLimit(value)
    }

    const handleClickDelete = async (userInfo) => {
        setIsLoading(true)
        await props.deleteLiveData({
            id: userInfo.id,
            success: async ({ data }) => {
                setIsLoading(false)
                await getSheets(page, limit)
            },
            fail: (err) => {
                setIsLoading(false)
            }
        })
    }

    const handleClickDisable = async (userInfo) => {
        setIsLoading(true)
        await props.updateUser({
            id: userInfo.id,
            body: { status: userInfo.status == 'active' ? 'disabled' : 'active' },
            success: async ({ data }) => {
                setIsLoading(false)
                await getSheets(page, limit)
            },
            fail: (err) => {
                setIsLoading(false)
            }
        })
    }

    const getSheets = async (page, limit) => {
        setIsLoading(true)
        await props.getLiveDataByUser({
            params: {
                user: props.currentUser.id,
                docName: searchValue ? searchValue : '',
                level: '2',
                sortBy: 'asc',
                limit,
                page,
            },
            success: ({ data }) => {
                setSheetList(data)
                setIsLoading(false)
            },
            fail: (err) => {
                //console.log(err)
                setIsLoading(false)
            }
        })
    }

    const datatableColums = [
        {
            title:"ID",
            key:"index",
            showOnResponse: true,
            showOnDesktop: true,
            render : (text, record, index) => (page - 1) * 10 + index+1,
        },
        {
            title: 'Sheet Name',
            dataIndex: 'docName',
            key: 'docName',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            name: 'Created at',
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            showOnResponse: true,
            showOnDesktop: true,
            render: (_, record) => <div>
                {moment(record.createdAt).format('MM/DD/YYYY HH:mm')}
            </div>
        },
        {
            name: 'Updated at',
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            showOnResponse: true,
            showOnDesktop: true,
            render: (_, record) => <div>
                {moment(record.updatedAt).format('MM/DD/YYYY HH:mm')}
            </div>
        },
        {
            name: 'Action',
            title: 'Action',
            showOnResponse: true,
            showOnDesktop: true,
            render: (_, record) => {
                return <div className='center-start'>
                    <div onClick={()=>{
                        history.push('/app/mysheet/'+record.id)
                    }} className='sheet-view-detail' style={{margin:'5px'}}>View</div>
                    {/* <Popconfirm title="Sure to delete?" onConfirm={() => handleClickDelete(record)}>
                        <a className='action-remove' >View</a>
                    </Popconfirm> */}
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleClickDelete(record)}>
                        <a className='action-remove' >Delete</a>
                    </Popconfirm>
                    {/* {record.status == 'disable' ? <Popconfirm title="Sure to active?"
                        onConfirm={() => handleClickDisable(record)}>
                        <a className='action-active' >Active</a>
                    </Popconfirm> : <Popconfirm title="Sure to disable?"
                        onConfirm={() => handleClickDisable(record)}>
                        <a className='action-disable'>Disable</a>
                    </Popconfirm>} */}
                </div>
            }
        }
    ]

    return (
        <>
            <div className='content-section'>
                <div className='center-between'>
                    <div className={cx(classes.title, 'pb-10')}>All Sheets</div>
                    <div className='searchBox position-relative'><form onSubmit={(e)=>{
                        e.preventDefault()
                        getSheets(page,limit)}}>
                            <input className='searchInputField'
                            placeholder='input email to search' 
                            onChange={(e)=>setSearchValue(e.target.value)}/>
                        <IconButton className='search-submit' type='submit'><BsSearch size={15}/></IconButton>
                        </form></div>
                </div>
                {isLoading ? <div>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <hr className={classes.subDivider}></hr>}
                <div className='table-responsive'>
                <Table 
                    columns={datatableColums}
                    dataSource={userList.docs}
                    pagination= {false}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            console.log("clicked row:",record,rowIndex)
                          }, // click row
                        }
                      }}
                    className="antd-Tbl"/>
                </div>
                <TablePagination
                    component='div'
                    style={{color:'white'}}
                    count={userList.totalDocs}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={handleChangeRowPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            </div>
        </>
    )
}

Sheets.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    refresh: PropTypes.func,
    getLiveDataByUser: PropTypes.func,
    deleteLiveData: PropTypes.func,
    updateUser: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
}

const actions = {
    setIsSubscribeLoading,
    getLiveDataByUser,
    refresh,
    deleteLiveData,
    updateUser,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
})

export default compose(connect(selector, actions))(Sheets)