export const SET_SIGNUP_OPEN = 'SET_SIGNUP_OPEN'
export const SET_SIGNIN_OPEN = 'SET_SIGNIN_OPEN'
export const SET_RESET_PASSWORD_OPEN = 'SET_RESET_PASSWORD_OPEN'
export const SET_CREATE_ACCOUNT_FIRST_OPEN = 'SET_CREATE_ACCOUNT_FIRST_OPEN'
export const SET_NEW_PASSWORD_OPEN = 'SET_NEW_PASSWORD_OPEN'
export const SET_NEED_TO_UPGRADE_OPEN = 'SET_NEED_TO_UPGRADE_OPEN'
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE'
export const SET_SUBSCRIBE_OPEN = 'SET_SUBSCRIBE_OPEN'
export const SET_SUCCESS_OPEN = 'SET_SUCCESS_OPEN'
export const SET_IS_SUBSCRIBE_LOADING = 'SET_IS_SUBSCRIBE_LOADING'
export const SET_RESET_PASSWORD_TOKEN = 'SET_RESET_PASSWORD_TOKEN'
export const SET_COOKIE = 'SET_COOKIE'
export const CLOSE_VERIFICATION = 'CLOSE_VERIFICATION'
export const CLEAR_GLOBAL = 'CLEAR_GLOBAL'

export const SET_LOTTIE_COLOR = 'SET_LOTTIE_COLOR'
export const SET_STROKE_PERCENT = 'SET_STROKE_PERCENT'
export const SET_EDITOR_LOTTIE_CHANGEMENT = 'SET_EDITOR_LOTTIE_CHANGEMENT'
export const SET_L2G_PERCENT = 'SET_L2G_PERCENT'
export const SET_REQUEST_VERIFY_OPEN = 'SET_REQUEST_VERIFY_OPEN'

