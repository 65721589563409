import React, { useState, useMemo } from 'react'
import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core'
import useStyles from './styles'
import * as cx from 'classnames'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { IoMdAlert } from 'react-icons/io'
import paymentAsset from 'assets/images/images/payment.png'
import regLock from 'assets/images/images/reg-lock.svg'
import regStripe from 'assets/images/images/reg-stripe.svg'
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js"
import subMark from 'assets/images/images/subMark.svg'


const OrderCard = (props) => {

    const classes = useStyles()

    console.log("props===990:",props)

    return (
        <>
            <div className={classes.cardSummary}>
                <div className={cx(classes.titleInCard, 'pb-10')}>Order Summary</div>
                {props.isYear?<div className='center-between pb-10'>
                    <div className={classes.sumPlan1}>Annual Plan</div>
                    <div className={classes.sumPrice}>$158.98/month</div>
                </div>:<div className='center-between pb-10'>
                    <div className={classes.sumPlan1}>Monthly Plan</div>
                    <div className={classes.sumPrice}>$19.98/month</div>
                </div>}
                <div className={cx('pb-10', classes.sumPlan2)}>(1 Device/ 7 day free Trial)</div>
                <hr className={classes.lineTwo}></hr>
                <div className={cx(classes.titleInCard, 'pt-10')}>Product and Offer Details</div>
                <div className='pb-10'>At the end of your trial period you will be charged 
                    {props.isYear?' $158.98':' $19.98'} for the first term. After the first term,
                    you will be automatically renewed at the renewal price
                    (currently {props.isYear?'$158.98/year':'$19.98/month'}). You can cancel at any time 
                    before you are charged.</div>
                <hr className={classes.lineOne}></hr>
                <div className='center-between pt-10'>
                    <div className={classes.titleInCard}>Order total</div>
                    <div>$0.00</div>
                </div>
            </div>
        </>
    )
}

export default OrderCard