import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import icon_dashboard from 'assets/images/navbar/icon_dashboard.png'
import icon_avatar from 'assets/images/navbar/icon_avatar.png'
import icon_invoice from 'assets/images/navbar/icon_invoice.png'
import icon_key from 'assets/images/navbar/icon_key.png'
import icon_logout from 'assets/images/navbar/icon_logout.png'
import Icon from "components/Icon"
import useStyles from './styles'
import * as cx from 'classnames'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { logout } from 'redux/modules/auth/actions'
import { authInfo, authClear } from 'helpers/localCheck'
import {
    clearGlobal,
  } from 'redux/modules/global/actions'
import { currentUserSelector } from 'redux/modules/auth/selectors'

import { RxDashboard } from 'react-icons/rx'
import { AiOutlineUser } from 'react-icons/ai'
import { TbFileDollar } from 'react-icons/tb'
import { FiKey } from 'react-icons/fi'
import { FiLogOut } from 'react-icons/fi'
import { useLocation, useHistory } from 'react-router-dom'
import NavList from 'helpers/NavList'
import NavListAdmin from '../../helpers/NavListAdmin'

const SideNav = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const [userLevel, setUserLevel] = useState(2)
    const onClick = async (e) => {
        if (e.path == '/signin') {
            const refreshToken = authInfo().tokens.refresh.token
            await props.logout({
                body: { refreshToken: refreshToken },
                success: async () => {
                    await props.clearGlobal()
                }
            })
            authClear()
            props.history.push('/')
        } else {
            history.push(e.path)
        }
    }
    return (
        <div
            style={{
                width: '220px',
            }}
            className={props.className}
        >
            {
                props.currentUser.level=="1" ? 
                NavListAdmin.map((item)=>{
                    return <Button onClick={() => onClick(item)}
                        className={cx(classes.navitem, classes.navItem_btn, 
                            item.path == "/signin" && classes.navItem_out,
                            props.path == item.path ? classes.navitem_active : "")}>
                        <Icon type={item.icon}
                            className={cx(classes.navitem,
                                props.path == item.path ? classes.navitem_active : "")} />
                        <div className='pl-10'>{item.label}</div></Button>
                })
                :
                NavList.map((item) => {
                    return <Button onClick={() => onClick(item)}
                        className={cx(classes.navitem, classes.navItem_btn, 
                            item.path == "/signin" && classes.navItem_out,
                            props.path == item.path ? classes.navitem_active : "")}>
                        <Icon type={item.icon}
                            className={cx(classes.navitem,
                                props.path == item.path ? classes.navitem_active : "")} />
                        <div className='pl-10'>{item.label}</div></Button>
                }
                )
            }
        </div>
    )
}

SideNav.propTypes = {
    logout: PropTypes.func,
    clearGlobal: PropTypes.func,
    currentUser: PropTypes.any,
}

const actions = {
    logout,
    clearGlobal,
}

const selector = createStructuredSelector({
    currentUser: currentUserSelector
})

export default compose(connect(selector, actions))(SideNav)