import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, withRouter, Redirect } from 'react-router-dom'
import Views from './views'
import Home from '../pages/Home'
import StartRegister from './StartRegister'
import TermsCondition from '../pages/TermPolicy/termCondition'
import Policy from '../pages/TermPolicy/policy'
import About from '../pages/TermPolicy/about'
import Disclaimer from '../pages/TermPolicy/disclaimer'
import { Subscribe } from 'containers/Subscribe'
import {SignIn, SignUp, ForgotPassword, Callback} from '../pages/Authenticate'
import { Success } from 'containers/Success'
import { CustomAlert } from 'components/CustomAlert'
import {
  signupOpenSelector,
  signinOpenSelector,
  resetPasswordOpenSelector,
  createAccountFirstOpenSelector,
  newPasswordOpenSelector,
  needToUpgradeOpenSelector,
  subscribeOpenSelector,
  subscribeInfoSelector,
  successOpenSelector,
  requestVerifyOpenSelector,
  successModalCustomInfoSelector,
  cookieSelector,
} from 'redux/modules/global/selectors'
import {
  currentUserSelector,
  authTokenSelector,
  authTotalStatusSelector
} from 'redux/modules/auth/selectors'
import { refreshToken } from 'redux/modules/auth/actions'
import {
  setSubscribeOpen,
  setSuccessOpen,
  setRequestVerifyOpen,
} from 'redux/modules/global/actions'
import { authInfo } from 'helpers/localCheck'

import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollToTop from 'react-router-scroll-top'
import { useHistory } from 'react-router-dom'

const Routes = ({
  setSuccessOpen,
  setRequestVerifyOpen,
  successOpen,
  requestVerifyOpen,
  successModalCustomInfo,
  authStatus,
  refreshToken,
  subscribeInfo,
  subscribeOpen
}) => {
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openRequestverify, setOpenRequestVerify] = useState(false)
  const [openUnauthAlert, SetOpenUnauthAlert] = useState(false)
  const [openSubscribe, setOpenSubscribe] = useState(false)
  const history = useHistory()

  useEffect(() => {
    AOS.init({
      offset: 50,
      once: true,
      duration: 700,
      delay: 50,
    })
  })

  useEffect(() => {
    const refreshAuth = async () => {
      const cur_refreshToken = authInfo().tokens.refresh.token
      await refreshToken({
        body: { refreshToken: cur_refreshToken }
      })
    }

    if (authInfo().user) {
      refreshAuth()
    }
  }, [refreshToken])

  useEffect(() => {
    authStatus === 'UNAUTHORIZED' ? SetOpenUnauthAlert(true) : SetOpenUnauthAlert(false)
    if(authStatus === 'UNAUTHORIZED'){
      setTimeout(()=>{
        localStorage.removeItem('trax_token')
        localStorage.removeItem('trax_user')
        if(history!=undefined)
        history.push('/signin')
        window.location.reload(false)
      },500)
    }
  }, [authStatus, SetOpenUnauthAlert])

  useEffect(() => {
    setOpenSuccess(successOpen)
    setOpenRequestVerify(requestVerifyOpen)
    setOpenSubscribe(subscribeOpen)
  }, [
    successOpen,
    subscribeOpen,
    requestVerifyOpen,
  ])

  return (
    <Router >
      <ScrollToTop>
        <CustomAlert
          isOpen={openUnauthAlert}
          type='error'
          disableAutoHide={true}
          text='Unauthorized! Please re-login.'
          onClose={() => SetOpenUnauthAlert(false)}
        />
        <Success
          open={openSuccess}
          onClose={() => setSuccessOpen({ open: false })}
          customInfo={successModalCustomInfo}
        />
        <Subscribe
          open={openSubscribe}
          info={subscribeInfo}
          onClose={() => setSubscribeOpen({ open: false, subscribeInfo: null })}
        />
        {/* <RequestVerify open={openRequestverify} onClose={() => setRequestVerifyOpen({ open: false })} /> */}

        <Route exact path='/' component={Home} />
        <Route path='/signin' component={SignIn} />
        <Route path='/signup' component={SignUp} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/start' component={StartRegister} />
        <Route path='/callback' component={Callback} />
        <Route path='/app' component={Views}/>
        <Route path='/terms' component={TermsCondition}/>
        <Route path='/policy' component={Policy}/>
        <Route path='/about' component={About}/>
        <Route path='/disclaimer' component={Disclaimer}/>
        {/* <Redirect path='/*' component={Home}/> */}
      </ScrollToTop>
    </Router>
  )
}

Routes.propTypes = {
  successOpen: PropTypes.bool,
  subscribeInfo: PropTypes.any,
  curretUser: PropTypes.any,
  authToken: PropTypes.any,
  successModalCustomInfo: PropTypes.any,
  cookie: PropTypes.any,
  setRequestVerifyOpen: PropTypes.func,
  setSuccessOpen: PropTypes.func,
  refreshToken: PropTypes.func,
  setSubscribeOpen: PropTypes.func,
  subscribeInfo: PropTypes.any,
  subscribeOpen: PropTypes.bool,
}

const actions = {
  setSuccessOpen,
  setRequestVerifyOpen,
  refreshToken,
  setSubscribeOpen,
}

const selector = createStructuredSelector({
  subscribeInfo: subscribeInfoSelector,
  successOpen: successOpenSelector,
  requestVerifyOpen: requestVerifyOpenSelector,
  curretUser: currentUserSelector,
  authToken: authTokenSelector,
  cookie: cookieSelector,
  successModalCustomInfo: successModalCustomInfoSelector,
  authStatus: authTotalStatusSelector,
  subscribeInfo: subscribeInfoSelector,
  subscribeOpen: subscribeOpenSelector,
})

export default compose(withRouter,connect(selector, actions))(Routes)
