import React, { useState } from 'react'
import useStyles from './Form.styles'
import {
  Button,
  CircularProgress
} from '@material-ui/core'
import { CustomInput } from 'components/CustomInput'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from 'redux/modules/auth/actions'
import { CustomAlert } from 'components/CustomAlert'
import { useHistory } from 'react-router-dom'
import * as cx from 'classnames'
import { validator } from 'helpers/validator'
import { signup } from '../../redux/modules/auth/actions'
import { BackgroundLine } from 'components/BackgroundLine'

const SignUpForm = ({
  signup,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [emailValidText, setEmailValidText] = useState('')
  const [passwordValidText, setPasswordValidText] = useState('')
  const [validationStr, setValidationStr] = useState([])
  const [alertOpen, setAlertOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)

  const handleSignup = () => {
    let validation_str = []
    validation_str.push(validator(name, ['require']))
    validation_str.push(validator(email, ['require', 'email']))
    validation_str.push(validator(password, ['require', 'password']))
    validation_str.push(validator(password, ['require', 'confirm_password'], confirmPassword))
    setValidationStr(validation_str)

    const isValid = validation_str.filter(item => item).length ? false : true
    if (!isValid) return
    setEmailValidText('')
    setPasswordValidText('')
    setIsWaiting(true)
    signup({
      body: { password, email, name },
      success: ({ data }) => {
        setIsWaiting(false)
        if (data.user.verifyStatus) {
          setTimeout(() => {
            if (data.user.level == "1" || data.user.level == "0") {
              history.push('/app/users')
            } else {
              history.push('/app/dashboard')
            }
          }, 800)
        } else {
          setAlertText("Please verify email from the inbox")
          setInfoOpen(true)
          setTimeout(() => {
            history.push('/signin')
          }, 800)
        }
      },
      fail: (err) => {
        console.log("err.data=============:", err)
        setIsWaiting(false)
        setAlertText(err.data.message)
        setAlertOpen(true)
      }
    })
  }

  const handleSignIn = () => {
    history.push('/signin')
  }

  console.log("validationStr:", validationStr)

  return (
    <>
      <BackgroundLine>
        <div className={classes.root}>
          <div className={cx(classes.title, 'pb-30')}>Sign Up</div>
          <CustomInput
            placeholder='Enter your Name'
            onChange={value => setName(value)}
            hasError={validationStr[0]}
          />
          <div className='pb-5'></div>
          <CustomInput
            placeholder='E-Mail'
            type="text"
            onChange={value => setEmail(value)}
            hasError={validationStr[1]}
          />
          <div className='pb-5'></div>
          <CustomInput
            placeholder='Password'
            type='password'
            onChange={value => setPassword(value)}
            hasError={validationStr[2]}
          />
          <CustomInput
            placeholder='Confirm Password'
            type='password'
            onChange={value => setConfirmPassword(value)}
            hasError={validationStr[3]}
          />
          <div className='pb-10'></div>
          {!isWaiting ? <Button className={cx(classes.home_btn)}
            onClick={handleSignup}>Sign Up</Button>
            : <Button className={cx(classes.home_btn)} disabled>
              <CircularProgress size={24} className={classes.buttonProgress} /></Button>}
          <div className='pb-20'></div>
          <div className={classes.label4}>
            If you have already account? <span onClick={handleSignIn} >Sign In</span>
          </div>
          {/* 'success' | 'info' | 'warning' | 'error' */}
          <CustomAlert isOpen={alertOpen} type='error' text={alertText} onClose={() => setAlertOpen(false)} />
          <CustomAlert isOpen={infoOpen} type='info' text={alertText} onClose={() => setInfoOpen(false)} />
        </div>
      </BackgroundLine>
    </>
  )
}

SignUpForm.propTypes = {
  signup: PropTypes.func,
}

const actions = {
  signup,
}

export default compose(connect(null, actions))(SignUpForm)
