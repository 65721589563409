import React from 'react'
import { CloseModal } from 'components/CloseModal'
import { Mobile, Default } from 'containers/ResponseLayout'
import Form from './Form'
import { MobileHeader } from 'containers/Header'
import { MobileFooter } from 'containers/Footer'
import { PageHeader } from 'components/PageHeader'

const ForgotForm = ({ onClose }) => {

  return (
    <>
      <Mobile>
        <Form />
      </Mobile>

      <Default>
          <Form />
      </Default>
    </>
  )
}

export default ForgotForm
