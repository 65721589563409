import React, { useState, useEffect, useRef } from 'react'
import './Home.scss'
import { Header, MobileHeader } from 'containers/Header'
import { HomeDetailSection } from 'containers/HomeDetailSection'
import { HomeDetailSectionTwo } from 'containers/HomeDetailSectionTwo'
import { LookingForSectionFive } from 'containers/LookingForSectionFive'
import { Footer, MobileFooter } from 'containers/Footer'
import { BackgroundLine } from 'components/BackgroundLine'
import { useHistory, useLocation } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import Viewer from 'react-viewer'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { createStructuredSelector } from 'reselect'
import { refresh } from 'redux/modules/auth/actions'
import { useMediaQuery } from 'react-responsive'
import { HomeTraxDescription } from '../../containers/HomeTraxDescription'
import FeatureOne from '../../containers/HomeTraxFeature/FeatureOne'
import FeatureTwo from '../../containers/HomeTraxFeature/FeatureTwo'
import FeatureThree from '../../containers/HomeTraxFeature/FeatureThree'
import FeatureFour from '../../containers/HomeTraxFeature/FeatureFour'
import FeatureSlider from '../../containers/HomeTraxFeature/FeatureSlider'

const Home = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [isLandingHeader, setIsLandingHeader] = useState(true)
  const [isOpen, setOpen] = useState(false)
  const [videoIndex, setVideoIndex] = useState(0)
  const [currentImage, setCurrentImage] = useState(
    "https://firebasestorage.googleapis.com/v0/b/flirty-db9bc.appspot.com/o/other"
    + "%2Fbinance-earn-1.png?alt=media&token=80fe6992-4aab-40e3-a88e-2fb89f4b4914")
  const [visible, setVisible] = useState(false)

  const refAbout = useRef(null)
  const refFeature = useRef(null)
  const refPricing = useRef(null)
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const youtubeList = [
    'gdP-NhEhRG8',
    'oj78yHIICWk',
  ]

  useEffect(() => {
    if (location.hash == '#about') {
      refAbout.current.scrollIntoView()
    } else if (location.hash == '#feature') {
      refFeature.current.scrollIntoView()
    } else if (location.hash == '#pricing') {
      refPricing.current.scrollIntoView()
    }
  }, [location])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    const len = window.location.search.length
    const value = window.scrollY > 50 ? false : true
    len === 0 && setIsLandingHeader(value)
  }

  const moveToStart = async (value) => {
    if (props.currentUser != null) {
      await props.refresh({
        success: ({ data }) => {
          history.push('/app')
        },
      })
    } else {
      if (value != undefined) {
        history.push('/start?mode=year')
      } else {
        history.push('/start')
      }
    }
  }

  return (
    <>
      <BackgroundLine>
        {isMobile ? <MobileHeader></MobileHeader> : <Header isLandingPage={isLandingHeader} />}
        <div className='home-header-section'>
          <div id='detail-description'>
            <HomeDetailSection
              moveToStart={moveToStart}
              showVideo={() => {
                setVideoIndex(0)
                setOpen(true)
              }} />
          </div>
        </div>
        <div ref={refAbout} className='pt-30'>
          <HomeDetailSectionTwo showVideo={() => {
            setVideoIndex(1)
            setOpen(true)
          }} />
        </div>
        <div ref={refFeature} className='pt-30' style={{
          backgroundImage: "url(/img/bg-03.jpg)",
          backgroundRepeat: 'no-repeat',
          width: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <div>
            <HomeTraxDescription />
          </div>
        </div>
        <div className='pt-30'>
          <FeatureSlider />
        </div>
        <div className='pt-30'>
          <FeatureOne />
        </div>
        <div className='pt-30'>
          <FeatureTwo />
        </div>
        <div className='pt-30'>
          <FeatureThree />
        </div>
        <div className='pt-30'>
          <FeatureFour />
        </div>
        <div ref={refPricing} className='home-detail-section'>
          <LookingForSectionFive moveToStart={(value) => moveToStart(value)} />
          <div style={{ height: isMobile ? '250px' : "0px" }} />
        </div>
        {isMobile ? <MobileFooter moveToStart={(value) => moveToStart(value)} /> : <Footer moveToStart={(value) => moveToStart(value)} />}
        <ModalVideo channel='youtube' youtube={{ autoplay: 1 }} size="lg" isOpen={isOpen}
          videoId={youtubeList[videoIndex]} onClose={() => setOpen(false)} />
        <Viewer
          visible={visible}
          onClose={() => { setVisible(false) }}
          images={[{ src: currentImage, alt: '' }]}
        />
      </BackgroundLine>
    </>
  )
}

Home.propTypes = {
  currentUser: PropTypes.any,
  refresh: PropTypes.func,
}

const actions = {
  refresh,
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
})
export default compose(connect(selector, actions))(Home)
