import React, { useState, useMemo, useEffect } from 'react'
import {
    LinearProgress,
    TablePagination,
} from '@material-ui/core'
import { Space, Table, Tag, Popconfirm } from 'antd'
import useStyles from './styles'
import * as cx from 'classnames'
import { ImBin } from 'react-icons/im'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector, last4Selector, adminInvoicesSelector }
    from 'redux/modules/subscribe/selectors'
import { isSubscribeLoadingSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { setIsSubscribeLoading } from 'redux/modules/global/actions'
import { getAdminInvoices } from 'redux/modules/subscribe/actions'
import { refresh } from 'redux/modules/auth/actions'
import {
    getSubscriptionInfo,
} from 'redux/modules/subscribe/actions'
import moment from 'moment'

const Transactions = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [invoices, setInvoices] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 25, 100, { value: -1, label: 'All' }])
    const [searchValue, setSearchValue] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        if (props.currentUser != null) {
            console.log("start transaction page")
            props.getAdminInvoices({
                params: {
                    email: searchValue ? searchValue : '',
                    sortBy: 'asc',
                    limit,
                    page,
                },
                success: ({ data }) => {
                    console.log("data.results:", data.results)
                    setInvoices(data)
                    setRowsPerPageOptions([10, 25, 100, { value: data.totalResults, label: 'All' }])
                },
                fail: (err) => {
                    //console.log(err)
                }
            })
        }
    }, [props.currentUser, location])

    const refreshUser = async () => {
        props.setIsSubscribeLoading({ isLoading: true })
        await props.refresh({
            success: ({ data }) => {
                props.setIsSubscribeLoading({ isLoading: false })
            },
        })
    }

    const handleChangePage = (e, value) => {
        setPage(value + 1)
    }

    const handleChangeRowPerPage = (e) => {
        const value = e.target.value
        setLimit(value)
    }

    const handleClickDelete = async (userInfo) => {
        setIsLoading(true)
        await props.deleteUser({
            id: userInfo.id,
            success: async ({ data }) => {
                setIsLoading(false)
                await getAdminInvoices(page, limit)
            },
            fail: (err) => {
                setIsLoading(false)
            }
        })
    }

    const getAdminInvoices = async (page, limit) => {
        await props.getAdminInvoices({
            params: {
                email: searchValue ? searchValue : '',

                sortBy: 'asc',
                limit,
                page,
            },
            success: ({ data }) => {
                setInvoices(data)
            },
            fail: (err) => {
                //console.log(err)
            }
        })
    }

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Amount(usd)',
            dataIndex: 'amount_paid',
            key: 'amount_paid',
            render: (_, record) => <div>${record.amount_paid / 100}</div>
        },
        {
            title: 'Receipt',
            dataIndex: 'hosted_invoice_url',
            key: 'hosted_invoice_url',
            render: (_, record) => <a href={record.hosted_invoice_url} target="_blank">
                View</a>
        },
        {
            title: 'Created at',
            dataIndex: 'created',
            key: 'created',
            render: (_, record) => (
                <div>{moment(record.created * 1000).format('MM/DD/YYYY')}</div>
            ),
        },
        {
            name: 'Action',
            title: 'Action',
            render: (_, record) => {
                console.log("table record:", record)
                return <div className='center-center'>
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleClickDelete(record)}>
                        <a className='action-remove' ><ImBin /></a>
                    </Popconfirm>
                </div>
            }
        }
    ]

    return (
        <>
            <div>
                <div className={cx(classes.title, 'pb-10')}>Transactions</div>
                {props.isSubscribeLoading ? <div>
                    <LinearProgress />
                    <div className={classes.loading}></div>
                </div> : <hr className={classes.subDivider}></hr>}
                <div className='table-responsive'>
                    <Table columns={columns} dataSource={invoices.results} pagination={false} className="antd-Tbl" />
                </div>
                <TablePagination
                    component='div'
                    count={invoices.totalResults}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={handleChangeRowPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
                <div>* The invoice link will take users to the stripe invoice link. </div>
            </div>
        </>
    )
}

Transactions.propTypes = {
    currentUser: PropTypes.any,
    setIsSubscribeLoading: PropTypes.func,
    getSubscriptionInfo: PropTypes.func,
    getAdminInvoices: PropTypes.func,
    refresh: PropTypes.func,
    isSubscribeLoading: PropTypes.any,
    last4: PropTypes.any
}

const actions = {
    setIsSubscribeLoading,
    getSubscriptionInfo,
    getAdminInvoices,
    refresh,
}

const selector = createStructuredSelector({
    isSubscribeLoading: isSubscribeLoadingSelector,
    currentUser: currentUserSelector,
    subscriptionInfo: subscriptionInfoSelector,
    invoices: adminInvoicesSelector,
    last4: last4Selector
})

export default compose(connect(selector, actions))(Transactions)