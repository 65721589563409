import React, { useState, useMemo, useEffect } from 'react'
import useStyles from './styles'
import * as cx from 'classnames'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { subscriptionInfoSelector } from 'redux/modules/subscribe/selectors'
import { createStructuredSelector } from 'reselect'
import {
  getSubscriptionInfo,
} from 'redux/modules/subscribe/actions'

import LiveData from './livedata'
import SheetName from './sheetName'
import LiveState from './liveState'
import DropdownInput from './dropdownInput'

const Mysheet = (props) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState('tab1')
  const [payStatus, setPayStatus] = useState(false)
  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  useEffect(()=>{
    if(props.currentUser.paypals!=undefined){
      if(props.currentUser.paypals.length>0){
        if(new Date(props.currentUser.paypals[0].current_period_end)>new Date()){
          setPayStatus(true)
        }
      }
    }
    if(props.currentUser.upgradeBy=="Admin" && props.currentUser.role=="annualUser"){
      setPayStatus(true)
    }
  },[props.currentUser])

  return (
    <>
      <div className='content-full-section'>
        <div className={classes.tablinkCover}>
          <SheetName />
          <button className={cx(classes.tablink, activeTab == "tab1" ?
            classes.activeTabLink : "")} onClick={() => handleTabClick('tab1')}>LIVE DATA</button>
          <button className={cx(classes.tablink, activeTab == "tab2" ?
            classes.activeTabLink : "")} onClick={() => payStatus?handleTabClick('tab2'):{}}>{payStatus?'LIVE STATS':'STATS(Locked)'}</button>
          <button className={cx(classes.tablink, activeTab == "tab3" ?
            classes.activeTabLink : "")} onClick={() => payStatus?handleTabClick('tab3'):{}}>{payStatus?'DROPDOWN INPUT':'DROPDOWN(Locked)'}</button>
        </div>
        <div id="tab1" className={cx(classes.tabcontent, activeTab == "tab1" ? "" : classes.tabcontentHide)}>
          <LiveData />
        </div>
        {activeTab == "tab2" ?
          <div id="tab2" className={cx(classes.tabcontent)}>
            <LiveState />
          </div> : <></>
        }
        <div id="tab3" className={cx(classes.tabcontent, activeTab == "tab3" ? "" : classes.tabcontentHide)}>
          <DropdownInput />
        </div>
      </div>
    </>
  )
}

Mysheet.propTypes = {
  currentUser: PropTypes.any,
  getSubscriptionInfo: PropTypes.func,
}

const actions = {
  getSubscriptionInfo
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  subscriptionInfo: subscriptionInfoSelector
})

export default compose(connect(selector, actions))(Mysheet)