import React, { useState } from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { DetailDescription } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import { VideoPlayer } from 'components/VideoPlayer'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
// import play from 'assets/images/Icon/play.svg'
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss'
import { IconButton } from '@material-ui/core'

const HomeDetailSection = (props) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
            <div className={classes.title0}>Detect & Learn</div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>Intro video</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay}>
                <IconButton onClick={() => props.showVideo()}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <DetailDescription moveToStart={props.moveToStart} />
          </Grid>
        </Mobile>

        <Default>
          <Grid item xs={6}>
            <DetailDescription moveToStart={props.moveToStart} />
          </Grid>
          <Grid item xs={6} className="center-center">
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>Intro video</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay}>
                <IconButton onClick={() => props.showVideo()}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
          </Grid>
        </Default>
      </Grid>
    </Container>
  )
}

export default HomeDetailSection
