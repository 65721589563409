import React, { useState, useEffect } from 'react'
import {
  Button,
  Input,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import {
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/lab'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import PropTypes from 'prop-types'
import useStyles from './SearchBar.styles'
import search from 'assets/images/Icon/search.svg'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { setSearchMode } from 'redux/modules/global/actions'
import { searchModeSelector } from 'redux/modules/global/selectors'
import { useHistory } from 'react-router-dom'
import * as cx from 'classnames'
import { useMediaQuery } from 'react-responsive'

const SearchBar = ({
  isLandingPage = false,
  inline,
  setSearchMode,
  searchMode,
  initialValue,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const isIpad = useMediaQuery({ minWidth: 601, maxWidth: 1200 })
  const [searchModeString, setSearchModeString] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [placeholder, setPlaceholder] = useState('')

  useEffect(() => {
    setSearchModeString(searchMode)
    setSearchValue('')
    searchMode === 'Icons' ? setPlaceholder('sports') : setPlaceholder('sports')
  }, [searchMode])
  useEffect(() => {
    initialValue && setSearchValue(initialValue)
  }, [initialValue])

  const handleMode = (e, mode) => {
    if (mode !== null && e.target.type !== 'text') {
      setSearchModeString(mode)
      setSearchMode({ mode })
      setSearchValue('')
    }
  }
  const handleChange = (e) => {
    setSearchValue(e.target.value)
  }
  const handleSearch = () => {
    history.push(`/search?mode=${searchModeString}${searchValue !== '' ? `&search=${searchValue}` : ''}`)
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch()
    }
  }
  const handleRemove = () => {
    setSearchValue('')
    !inline && history.push(`/search?mode=${searchModeString}`)
  }

  return (
    <>
      {
        inline &&
        <div className={classes.root}>
          <div>
            <ToggleButtonGroup
              value={searchModeString}
              exclusive
              onChange={handleMode}
              className={classes.group}
            >
              {(isLandingPage || !isIpad) && <ToggleButton
                className={classes.toggleButton}
                value='Icons'
              >Icons</ToggleButton>
              }
              {(isLandingPage || !isIpad) && <ToggleButton
                className={classes.toggleButton}
                value='Illustrations'
              >Illustrations</ToggleButton>
              }
              <div>
                <Input
                  disableUnderline
                  className={cx(classes.textField, (!isLandingPage && isIpad) && classes.ipadSearchbar)}
                  placeholder={`Try “${placeholder}” ${!isIpad ? 'for example' : ''}`}
                  startAdornment={
                    <InputAdornment position='start' className={classes.adornment}>
                      <img src={search} alt='search' />
                    </InputAdornment>
                  }
                  endAdornment={
                    searchValue &&
                    <InputAdornment position='end' className={classes.adornment}>
                      <IconButton
                        onClick={handleRemove}
                        size='small'
                      >
                        <CancelRoundedIcon fontSize='inherit' />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={searchValue}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                />
              </div>
              <Button className={classes.searchButton} onClick={handleSearch}>Search</Button>

            </ToggleButtonGroup>
          </div>
        </div>
      }
      {
        !inline &&
        <div className={classes.rootSimple}>
          <Input
            disableUnderline
            className={classes.soloTextField}
            placeholder={`Try “${placeholder}” for example`}
            startAdornment={
              <InputAdornment position='start' className={classes.adornment}>
                <img src={search} alt='search' />
              </InputAdornment>
            }
            endAdornment={
              searchValue &&
              <InputAdornment position='end' className={classes.adornment}>
                <IconButton
                  onClick={handleRemove}
                  size='small'
                >
                  <CancelRoundedIcon fontSize='inherit' />
                </IconButton>
              </InputAdornment>
            }
            onKeyDown={handleKeyDown}
            value={searchValue}
            onChange={handleChange}
          />
          <Button
            className={cx(classes.searchButton, classes.mergeButton)}
            onClick={handleSearch}
          >Search</Button>
        </div>
      }
    </>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  isLandingPage: PropTypes.bool,
  inline: PropTypes.bool,
  setSearchMode: PropTypes.func,
  searchMode: PropTypes.string,
}

const actions = {
  setSearchMode,
}

const selector = createStructuredSelector({
  searchMode: searchModeSelector,
})

export default compose(connect(selector, actions))(SearchBar)
