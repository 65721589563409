import { makeStyles } from "@material-ui/styles"
export default makeStyles(theme => ({
  title: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '40px',
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '32px',
    }
  },
  cardSummary: {
    background: '#FFFFFF',
    /* Main color */
    border: '1px solid #009348',
    borderRadius: '10px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '40px',
    color: '#449342',
    padding: '35px 35px',
    height: 'fit-content',
    width: '600px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '18px',
      lineHeight: '36px',
      padding: '25px 25px',
      width: '400px'
    }
  },
  cardTitle: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '38px',
    lineHeight: '40px',
    color: '#009348',
  },
  cardTitle002: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '30px',
    lineHeight: '32px',
    color: '#009348',
  },
  managerCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    padding: '20px 20px 30px',
    gap: '15px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    marginTop: '15px',
    marginBottom: '15px',
    position: 'relative',
    width:'fit-content',
    '@media only screen and (max-width: 600px)': {
      width:'100%',
    }
  },
  priceTop: {
    position: 'absolute',
    top: '-6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  managerTitle: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '20px',
      lineHeight: '26px',
    }
  },
  managerSubPrice: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#333333',
    paddingTop: '10px'
  },
  managerPrice: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: '39px',
    /* identical to box height */
    textAlign: 'center',
    /* Main color */
    paddingBottom: '20px',
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '22px',
      lineHeight: '39px',
    }
  },
  cardText:{
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '22px',
    /* identical to box height */
    textAlign: 'left',
    /* Main color */
    color: '#009348',
    '@media only screen and (max-width: 600px)': {
      fontSize: '18px',
      lineHeight: '20px',
    }
  },
  managerDetail: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    /* Dark */
    color: '#333333',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
  managerDetailUnder: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    /* Dark */
    color: '#333333',
    textDecoration: 'underline',
    cursor: 'pointer',
    '@media only screen and (max-width: 600px)': {
      fontSize: '14px',
      lineHeight: '21px',
    }
  },
  cancelDesc: {
    
  },
  cardEnd: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    /* Dark */
    color: '#333333',
  },
  defaultBtn: {
    width: '200px'
  },
  home_btn_buy: {
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    // font-weight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '7px',
    paddingBottom: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },
  home_btn: {
    background: '#009348',
    boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    cursor: 'pointer',
    width: '200px',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'white',
      color: '#009348',
    },
  },
  home_btn_white: {
    background: '#FFFFFF',
    // boxShadow: '0px 0px 4px',
    border: '1px solid #009348',
    borderRadius: '4px',
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#009348',
    cursor: 'pointer',
    width: '200px',
    textTransform: 'capitalize',
    '&:hover': {
      background: '#e5e5e5',
      color: '#009348',
    },
  },
  widthJust:{
    width:'fit-content!important',
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  inputField: {
    border: '1px solid #dbdbdb',
    padding: '12px 10px',
    borderRadius: '3px',
    width: '100%',
    fontSize: '18px',
    color: '#424770',
    '@media only screen and (max-width: 600px)': {
      fontSize: '16px',
    }
  },
  posAlert: {
    position: 'absolute',
    right: '9px',
    top: '15px'
  },
  poweredby: {
    fontFamily: 'Gotham',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#009348',
  },
  subDivider: {
    borderTop: '1px solid lightgrey'
  },
}))