import React from 'react'
import {
  Container,
  Grid
} from '@material-ui/core'
import useStyles from './styles'
import { DetailDescriptionTwo } from 'components/DetailDescription'
import { Mobile, Default } from 'containers/ResponseLayout'
import { VideoPlayer } from 'components/VideoPlayer'
import logo_video from 'assets/images/images/logo_video.png'
import play_icon from 'assets/images/Icon/play.svg'
import { IconButton } from '@material-ui/core'

const HomeDetailSection = (props) => {
  const classes = useStyles()
  const mondayVideo = `https://s3.us-east-2.amazonaws.com/MyGameTrax.com/HighlightMovie/MyGameTrax_Web.mp4`

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Mobile>
          <Grid item xs={12}>
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>How to use</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay} onClick={() => props.showVideo()}>
                <IconButton onClick={() => props.showVideo()}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <DetailDescriptionTwo />
          </Grid>
        </Mobile>

        <Default className="center-between">
          <Grid item xs={6} className="center-center">
            <div className={classes.videoCover}>
              <div className={classes.videoBox}>
                <div>How to use</div>
                <div className={classes.logoVideo}><img className={classes.logoImg} src={logo_video} alt='' /></div>
                <div>&nbsp;</div>
              </div>
              <div className={classes.videoPlay} onClick={() => props.showVideo()}>
                <IconButton onClick={() => props.showVideo()}>
                  <img src={play_icon} alt='p' />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <DetailDescriptionTwo />
          </Grid>
        </Default>
      </Grid>

    </Container>
  )
}

export default HomeDetailSection
