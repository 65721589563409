import React, { useState, useRef, useEffect } from 'react'
import * as cx from 'classnames'
import useStyles from './styles'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { dropdownInputSelector, sheetStatusSelector, liveDataSelector } from 'redux/modules/sheet/selectors'
import { getDropdownInput, updateDropdownInput, createLiveData, 
    updateLiveData, updateSheetName, getLiveData } from 'redux/modules/sheet/actions'
import { refresh } from 'redux/modules/auth/actions'

function SheetName(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [sheetName, setSheetName] = useState('Untitled Sheet')
    useEffect(() => {
        if (props.liveData.docName != undefined) {
            setSheetName(props.liveData.docName)
        } else {
            setSheetName("Untitled Sheet")
        }
    }, [props.liveData])
    const handleEditName = (e) => {
        console.log("clicked handleEditName", e.target.value)
        setSheetName(e.target.value)
        dispatch(updateSheetName(e.target.value))
    }

    const handleBlueName = (e) => {
        if (sheetName.trim() == "") {
            setSheetName("Untitled Sheet")
        } else {
            console.log("detect handleblue name:", sheetName)
            if (props.sheetStatus != 'PENDING' && props.liveData.id != undefined) {
                props.updateLiveData({
                    id: props.liveData.id,
                    body: {
                        "id": props.liveData.id,
                        "docName": sheetName
                    }
                })
            }
        }
    }
    return (
        <div className={classes.nameInput}>
            <div className="sheet-name-container">
                <input
                    type="text"
                    className="sheet-name-input"
                    value={sheetName}
                    onChange={handleEditName}
                    onBlur={() => handleBlueName(null)}
                    onClick={(e) => e.target.select()}
                />
            </div>
        </div>
    )
}

SheetName.propTypes = {
    currentUser: PropTypes.any,
    refresh: PropTypes.func,
    livedataId: PropTypes.any,
    getLiveData: PropTypes.func,
    createLiveData: PropTypes.func,
    updateLiveData: PropTypes.func,
    liveData: PropTypes.any
}

const actions = {
    createLiveData,
    updateLiveData,
    getLiveData,
    refresh,
}

const selector = createStructuredSelector({
    currentUser: currentUserSelector,
    sheetStatus: sheetStatusSelector,
    liveData: liveDataSelector
})

export default compose(connect(selector, actions))(SheetName)

