import React, { useState, useRef, useEffect } from 'react'
import EditableDropDownRow from '../../../components/EditableDropDownRow'
import useStyles from './styles'
import * as cx from 'classnames'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { currentUserSelector } from 'redux/modules/auth/selectors'
import { dropdownInputSelector, sheetStatusSelector } from 'redux/modules/sheet/selectors'
import { getDropdownInput } from 'redux/modules/sheet/actions'
import { updateDropdownInput } from 'redux/modules/sheet/actions'
import { refresh } from 'redux/modules/auth/actions'
import { roles } from 'helpers/AppConfig'
import { CustomAlert } from 'components/CustomAlert'
import { downloadStringAsCsv } from '../../../helpers/downloadStringAsCsv'
import Papa from "papaparse"
import { DROP_INPUT_KEYS } from 'helpers/utils'

const columnsWithSelect = {
  "country": ["", "USA", "Canada", "UK", "Australia"]
}

function DropdownInput(props) {
  const classes = useStyles()
  const [liveDropRows, setRows] = useState([])
  const [tableKeys, setTableKeys] = useState(DROP_INPUT_KEYS)
  const [columnWidths, setColumnWidths] = useState({})

  const [isResizing, setIsResizing] = useState(false)
  const [resizePos, setResizePos] = useState({ startX: 0, columnKey: '' })

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [alertMode, setAlertMode] = useState('error')
  const [selectedFile, setSelectedFile] = useState(null)
  const [downkey, setDownKey] = useState("aaa")
  const [scaleSize, setScaleSize] = useState("scale100")
  const [minStaticWidth, setMinStaticWidth] = useState(100)

  useEffect(() => {
    props.getDropdownInput({
      body: { "userId": props.currentUser.id },
      success: (data) => {
        parsingDropdown(data.data.keys)
        // let contentData = data.data.content
        // parsingDropdownOld(contentData)
      },
      fail: (err) => {
        console.log("get dropdownninput failed:", err)
      }
    })
  }, [props.currentUser])

  const parsingDropdown = (dropContent) => {
    var keysDB = JSON.parse(dropContent)
    var longKey = ""
    Object.keys(keysDB).map((key, index) => {
      if (longKey == "") {
        longKey = key
      } else {
        if (keysDB[key].length > keysDB[longKey].length) {
          longKey = key
        }
      }
    })
    let allRow = []
    for (var i = 0; i < keysDB[longKey].length; i++) {
      const newRow = tableKeys.reduce((acc, key) => {
        if (key === "id") {
          acc[key] = new Date().getTime()-30000+i*20
        } else {
          if(keysDB[key]!=undefined){
            if(keysDB[key].length>i){
              acc[key]=keysDB[key][i]
            }else{
              acc[key] = ""
            }
          }else{
            acc[key] = ""
          }
        }
        return acc
      }, {})
      allRow = [...allRow, newRow]
    }

    console.log("allRow===:",allRow)

    setRows(allRow)
  }

  const handleSelectRow = (id) => {
    // const newRows = liveDropRows.map(row => {
    //   if (row.id === id) {
    //     return { ...row, isSelected: !row.isSelected }
    //   }
    //   return row
    // })
    // setRows(newRows)
  }

  const handleAddRow = () => {
    const newRow = tableKeys.reduce((acc, key) => {
      if (key === "id") {
        acc[key] = new Date().getTime()
      } else {
        acc[key] = "" // or any value you want to assign
      }
      return acc
    }, {})
    setRows([...liveDropRows, newRow])
  }

  const handleRemoveRow = (id) => {
    setRows(liveDropRows.filter(row => row.id !== id))
  }

  const handleRowChange = (id, event) => {
    const { name, value } = event.target
    let newRows = liveDropRows.map((row) => {
      if (row.id == id) {
        return { ...row, [name]: value.toUpperCase() }
      }
      return row
    })
    setRows(newRows)
    let contentData = ""
    let newColumns = {}
    newRows.map(row => {
      {
        Object.keys(row).map((key, index) => {
          //save sheet as string
          contentData += row[key] + ",."
          //save sheet by keys: empty value doesn't be saved.
          if (key != "id" && row[key] != "") {
            if (newColumns[key] != undefined) {
              newColumns[key].push(row[key])
            } else {
              newColumns[key] = [row[key]]
            }
          }
        })
      }
      contentData = contentData.slice(0, -2)
      contentData += "\n"
    })
    props.updateDropdownInput({
      body: {
        "user": props.currentUser.id,
        "content": contentData,
        "keys": JSON.stringify(newColumns),
        "type": 'dropdowninput'
      }, success: ({ data }) => {

      },
      fail: () => {
      }
    })
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (!isResizing) return
      const newWidth = Math.max(30, resizePos.startWidth + (event.clientX - resizePos.startX))
      setColumnWidths((prev) => ({ ...prev, [resizePos.columnKey]: newWidth }))
    }

    const handleMouseUp = () => setIsResizing(false)

    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isResizing, resizePos])

  const handleMouseDown = (columnKey, startWidth, event) => {
    setIsResizing(true)
    setResizePos({ startX: event.clientX, columnKey, startWidth })
  }

  const handleDownload = () => {

    if (props.currentUser.role == roles[0]) {
      setAlertMode("error")
      setAlertOpen(true)
      setAlertText("Please upgrade your account as premium")
      return
    }
    let contentData = ""

    tableKeys.map(key => {
      if (key == "id") {
        contentData += ","
      } else {
        contentData += key + ","
      }
    })

    contentData = contentData.slice(0, -1)
    contentData += "\n"

    liveDropRows.map(row => {
      {
        Object.keys(row).map((key) => {
          if (key == "id") {
            // contentData += ","
          } else {
            if(row[key].includes(",")){
              contentData += "\""+row[key]+"\""+ ","
            }else{
              contentData += row[key]+ ","
            }
          }
        })
      }
      contentData = contentData.slice(0, -1)
      contentData += "\n"
    })
    contentData = contentData.slice(0, -1)
    // downloadStringAsCsv(contentData,props.sheetName+"_"+new Date().getTime().toString()+".csv")
    downloadStringAsCsv(contentData, props.sheetName + '_DropdownInput' + ".csv")
  }

  const handleOnChange = (e) => {
    console.log("run new handleOnChange===:",e)
    setSelectedFile(e.target.files[0])
    setRows([])
    console.log("new handleOnChange===:",e.target.files[0])
    const files = e.target.files
    const keyRow = []
    if (files) {
      Papa.parse(files[0], {
        complete: function (results) {
          let newRowsFile = []
          let emptyIndexs = []
          results.data.map((ele, index) => {
            if (index == 0) {
              ele.map((item, order) => {
                if (item != "") {
                  keyRow.push(item)
                } else {
                  emptyIndexs.push(order)
                }
              })
            } else {
              let eleRow = []///remove value if key doesn't exist.
              ele.map((item, order) => {
                if (!emptyIndexs.includes(order)) {
                  eleRow.push(item)
                }
              })
              let oneRow = { "id": new Date().getTime() - 3000 + index }
              eleRow.map((item, index) => {
                oneRow = { ...oneRow, [keyRow[index]]: item }
              })
              newRowsFile.push(oneRow)
            }
          })
          setDownKey(downkey + "a")
          setRows(newRowsFile)
          console.log("new keys===:",newRowsFile)
          let contentData = ""
          let newColumns = {}
          newRowsFile.map(row => {
            {
              Object.keys(row).map((key, index) => {
                //save sheet as string
                contentData += row[key] + ",."
                //save sheet by keys: empty value doesn't be saved.
                if (key != "id" && row[key] != "") {
                  if (newColumns[key] != undefined) {
                    newColumns[key].push(row[key])
                  } else {
                    newColumns[key] = [row[key]]
                  }
                }
              })
            }
            contentData = contentData.slice(0, -1)
            contentData += "\n"
          })

          console.log("new keys===:",newColumns)
          props.updateDropdownInput({
            body: {
              "user": props.currentUser.id,
              "content": contentData,
              "keys": JSON.stringify(newColumns),
              "type": 'dropdowninput'
            }, success: ({ data }) => {
            },
            fail: () => {
            }
          })
        }
      }
      )
    }
  }

  console.log("props=========:",props.currentUser)

  const handleChangeScale = (e) => {
    setScaleSize(e.target.value)
    if (e.target.value == "scale50") {
      setMinStaticWidth(50)
    } else if (e.target.value == "scale75") {
      setMinStaticWidth(75)
    } else if (e.target.value == "scale90") {
      setMinStaticWidth(90)
    } else if (e.target.value == "scale100") {
      setMinStaticWidth(100)
    } else if (e.target.value == "scale125") {
      setMinStaticWidth(125)
    } else if (e.target.value == "scale150") {
      setMinStaticWidth(150)
    } else if (e.target.value == "scale200") {
      setMinStaticWidth(200)
    }
  }

  return <>
    <div className="featureBtnRow center-start">
      <Button variant="primary" onClick={handleAddRow}>Add Row</Button>
      <Button variant="primary" onClick={handleDownload}>Download</Button>

      <input
        type="file"
        id="file-upload-dropdown"
        onChange={handleOnChange}
        accept='.csv'
        style={{ display: 'none' }}
      />
      <button onClick={() => document.getElementById('file-upload-dropdown').click()}>
        Upload File
      </button>
      <select name="expanded" id="expand" value={scaleSize} onChange={handleChangeScale}>
        <option value="scale50">50%</option>
        <option value="scale75">75%</option>
        <option value="scale90">90%</option>
        <option value="scale100">100%</option>
        <option value="scale125">125%</option>
        <option value="scale150">150%</option>
        <option value="scale200">200%</option>
      </select>
      {selectedFile && (
        <p style={{color:'white'}}>&nbsp;&nbsp; Selected file: {selectedFile.name}</p>
      )}
    </div>
    <div className='inputTable' key={downkey} style={{maxHeight: 'calc(100vh - 175px)', marginBottom:'0px'}}>
      <table className={cx(scaleSize)} id={scaleSize}>
        <thead>
          <tr>
            {tableKeys.map((head) => {
              return head === "id" ?
                <></>
                : <th key={head} style={{
                  minWidth: columnWidths[head] != undefined ? `${columnWidths[head]}px` : 'fit-content', whiteSpace: 'nowrap'
                }}>
                  <div style={{ position: 'relative', userSelect: 'none' }}>
                    {head}
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: -2,
                        bottom: 0,
                        width: '5px',
                        cursor: 'col-resize',
                      }}
                      onMouseDown={(e) => handleMouseDown(head, columnWidths[head] != undefined ? columnWidths[head] : 100, e)}
                    />
                  </div>
                </th>
            })}
          </tr>
        </thead>
        <tbody>
          {liveDropRows.map((row, index) => (
            <EditableDropDownRow
              key={row.id}
              rowNumber={index + 1}
              row={row}
              isLastRow={index === liveDropRows.length - 1}
              dataRows={liveDropRows}
              addNewRow={handleAddRow}
              handleRowChange={handleRowChange}
              handleRemoveRow={handleRemoveRow}
              columnsWithSelect={columnsWithSelect}
              handleSelectRow={handleSelectRow}
            />
          ))}
        </tbody>
      </table>
      <CustomAlert
        isOpen={alertOpen}
        type={alertMode}
        text={alertText}
        onClose={() => setAlertOpen(false)}
      />
    </div>
  </>
}

DropdownInput.propTypes = {
  currentUser: PropTypes.any,
  sheetStatus: PropTypes.any,
  refresh: PropTypes.func,
  getDropdownInput: PropTypes.func,
  updateDropdownInput: PropTypes.func,
}

const actions = {
  getDropdownInput,
  updateDropdownInput,
  refresh,
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  dropdownInput: dropdownInputSelector,
  sheetStatus: sheetStatusSelector,
})

export default compose(connect(selector, actions))(DropdownInput)