import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Popper,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Grow,
  Paper
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import useStyles from './Header.styles.js'
import logo_notext from 'assets/images/logo/logo_notext.png'
import logo_video from 'assets/images/logo/logo_notext.png'
import svgUpArrow from 'assets/images/Icon/up-arrow.svg'
import svgDownArrow from 'assets/images/Icon/down-arrow.svg'
import svgUpArrowWhite from 'assets/images/Icon/up-arrow_white.svg'
import svgDownArrowWhite from 'assets/images/Icon/down-arrow_white.svg'
import { CustomButton } from 'components/CustomButton'
import { SearchBar } from 'containers/SearchBar'
import { useHistory, useLocation } from 'react-router-dom'
import * as cx from 'classnames'
import {
  setRequestVerifyOpen,
  clearGlobal,
} from 'redux/modules/global/actions'
import { clearLiveData } from 'redux/modules/sheet/actions'
import { logout } from 'redux/modules/auth/actions'
import { currentUserSelector } from 'redux/modules/auth/selectors'
import { isClosedVerificationSelector } from 'redux/modules/global/selectors'
import { createStructuredSelector } from 'reselect'
import { authInfo, authClear } from 'helpers/localCheck'
import { useMediaQuery } from 'react-responsive'
import { mongoObjectId } from '../../helpers/liveData'

const dropContent = [
  { text: 'New sheet', to: '/app/mysheet' },
  { text: 'All sheets', to: '/app/sheets' },
  { text: 'Subscription', to: '/app/subscription' },
  // { text: 'Profile', to: '/app/profile' },
  { text: 'Change Password', to: '/app/changepassword' },
  { text: 'Logout', to: 'logout' }
]

const dropAdminContent = [
  { text: 'Users', to: '/app/users' },
  { text: 'New sheet', to: '/app/mysheet' },
  { text: 'All sheets', to: '/app/sheets' },
  // { text: 'Profile', to: '/app/profile' },
  { text: 'Change Password', to: '/app/changepassword' },
  { text: 'Logout', to: 'logout' }
]

const Header = ({
  isLandingPage,
  searchBox,
  currentUser,
  logout,
  isMovable,
  clearGlobal,
  clearLiveData,
  isClosedVerification,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const isIpad = useMediaQuery({ minWidth: 601, maxWidth: 992 })
  const logo_img = isLandingPage ? logo_notext : logo_notext
  const [classType, setClassType] = useState(classes.landingRoot)
  const [buttonType, setButtonType] = useState('header')
  const [svgArrow, setSvgArrow] = useState(svgDownArrowWhite)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isLogedIn, setIsLogedIn] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [dropDownContent,setDropDownContent]=useState(dropContent)

  useEffect(() => {
    if (currentUser != null) {
      setIsLogedIn(currentUser.verifyStatus === true ? true : false)
      if(currentUser.level=="0" || currentUser.level=="1"){
        setDropDownContent(dropAdminContent)
      }
    }else{
      setIsLogedIn(false)
    }
    // setIsLogedIn(currentUser ? true : false)
  }, [currentUser, isClosedVerification, setAlertOpen, setAlertText])

  useEffect(() => {
    const setClassByType = () => {
      if (isLandingPage) {
        setClassType(classes.landingRoot)
        setButtonType('header')
        setSvgArrow(svgDownArrowWhite)
      } else {
        setClassType(classes.normalRoot)
        setButtonType('outLine')
        setSvgArrow(svgDownArrow)
      }
    }
    setClassByType()
  }, [isLandingPage, classes])

  const handleUserToggle = (e) => {
    setOpenDropdown(!openDropdown)
    setAnchorEl(e.currentTarget)
    const downArrow = isLandingPage ? svgDownArrowWhite : svgDownArrow
    const upArrow = isLandingPage ? svgUpArrowWhite : svgUpArrow
    setSvgArrow(openDropdown ? downArrow : upArrow)
  }
  const handleClose = () => {
    setOpenDropdown(false)
    setSvgArrow(isLandingPage ? svgDownArrowWhite : svgDownArrow)
  }
  const handleClick = (url) => async () => {
    setOpenDropdown(false)
    if (url === 'logout') {
      if (authInfo().tokens != null) {
        const refreshToken = authInfo().tokens.refresh.token
        await logout({
          body: { refreshToken: refreshToken },
        })
      } else {
        await clearGlobal()
      }
      await clearGlobal()
      authClear()
      history.push('/')
    } else if(url==='/app/mysheet'){
      console.log(["generateObjectId()====:",mongoObjectId()])
      history.push(url+'/'+ mongoObjectId())
    }else{
      history.push(url)
    }
  }
  const handleNewSheet = () => {
    history.push('/app/mysheet/'+ mongoObjectId())
  }
  const handleSignup = () => {
    // setSignupOpen({ open: true })
    history.push('/signup')
  }
  const handleSignin = () => {
    history.push('/signin')
  }

  return (
    <>
      <AppBar className={classType} position={isMovable ? 'absolute' : 'fixed'}>
        <Container>
          <Toolbar className={classes.toolbar}>
            <img className={classes.image} src={isMovable ?logo_img:logo_video} alt='logo' onClick={handleClick('/')} />
            <div className={classes.searchBoxArea}>
              {
                searchBox && !isIpad &&
                <SearchBar inline={true} />
              }
            </div>
            <Button className={classes.menuButton} onClick={handleClick('/')}>Home</Button>
            <Button className={classes.menuButton} onClick={handleClick('/about')}>About</Button>
            <Button className={classes.menuButton} onClick={handleClick('/#feature')}>Features</Button>
            <Button className={classes.menuButton} onClick={handleClick('/#pricing')}>Pricing</Button>
            {/* {
              !isLogedIn &&
              <Button className={classes.menuButton} onClick={handleSignin}>Sign In</Button>
            } */}
            {
              isLogedIn &&
              <Button
                className={cx(classes.menuButton, classes.userButton)}
                // startIcon={<img src={user_img} alt='user' />}
                // endIcon={<img src={svgArrow} alt='arrow' />}
                onClick={handleUserToggle}
              >{authInfo().user.name}
              </Button>
            }
            {/* {
              isLogedIn &&
              <Button className={classes.menuButton} onClick={handleUserToggle}>My Account</Button>
            } */}
            {
              !isLogedIn &&
              <CustomButton
                className={classes.startFreeButton}
                // content='Sign Up'
                content='Get Start'
                type={buttonType}
                onClick={handleSignin}
              />
            }
            {
              isLogedIn &&
              <Popper open={openDropdown} anchorEl={anchorEl} transition disablePortal>
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper className={cx(classes.dropPaper, isLandingPage ? 
                      classes.landingDropPaper:classes.unLandingDropPaper)}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <List component='nav'>
                          {
                            dropDownContent.map((item, key) => (
                              <ListItem
                                button
                                component='a'
                                className={cx(classes.linkItem, isLandingPage && classes.landingLinkItem)}
                                onClick={handleClick(item.to)}
                                key={key}
                              >
                                <ListItemText
                                  primary={item.text}
                                  className={cx(classes.linkText, isLandingPage && classes.landingLinkText)}
                                />
                              </ListItem>
                            ))
                          }
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            }
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

Header.propTypes = {
  isLandingPage: PropTypes.bool,
  searchBox: PropTypes.bool,
  isLogedIn: PropTypes.bool,
  isClosedVerification: PropTypes.bool,
  currentUser: PropTypes.any,
  setRequestVerifyOpen: PropTypes.func,
  logout: PropTypes.func,
  clearGlobal: PropTypes.func,
  clearLiveData: PropTypes.func,
}

const actions = {
  setRequestVerifyOpen,
  logout,
  clearGlobal,
  clearLiveData
}

const selector = createStructuredSelector({
  currentUser: currentUserSelector,
  isClosedVerification: isClosedVerificationSelector
})

export default compose(connect(selector, actions))(Header)
