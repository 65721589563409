import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({
  root: {
    width: '600px',
    '@media only screen and (max-width: 800px)': {
      width: '450px',
    },
    '@media only screen and (max-width: 500px)': {
      width: '350px',
    }
  },
}))
